import React, {useState} from 'react';
import './CommitmentItem.scss'
import {getTranslations} from "../../helpers/translations";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {getCommitmentRequest, removeCommitmentRequest} from "../../actions/commitments";
import {useDispatch, useSelector} from "react-redux";
import CommitmentModal from "../modals/CommitmentModal/CommitmentModal";
import ActionButton from "../buttons/ActionButton/ActionButton";
import ConfirmModal from "../modals/ConfirmModal/ConfirmModal";

const CommitmentItem = ({item, curLang, path, admin}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const commitment = useSelector(state => state.commitments.commitment);
  const dispatch = useDispatch();
  const transformedPath = typeof path === 'string' ? path : path.join('/');

  return (
    <div className='commitmentItem'>
      <p><b>{getTranslations('commitment_title', curLang)} №{item.number}</b></p>
      <p>{curLang === 'uk' ? item.name_ua : item.name_en}</p>
      <Link to={`/commitment/${transformedPath}/${item._id}`}/>
      <span
        className={`execution execution-${isNaN(item.execution) ? 'n_a' : Math.ceil(item.execution/20)}`}>
        {isNaN(item.execution) ? item.execution : item.execution + '%'}
      </span>

      {admin && (
        <div className="actionButton-wrap">
          <ActionButton
            edit
            doAction={() => dispatch(getCommitmentRequest({path: transformedPath, id: item._id}, setIsModalOpen))
          }/>
          <ActionButton remove doAction={() => setIsConfirmModalOpen(true)}/>
        </div>
      )}

      <CommitmentModal
        titleSlug='edit_commitment'
        curLang={curLang}
        open={isModalOpen}
        setIsModalOpen={() => setIsModalOpen(false)}
        path={path}
        commitment={commitment}
      />

      <ConfirmModal
        titleSlug='commitment_title_lower_case'
        curLang={curLang}
        open={isConfirmModalOpen}
        setIsModalOpen={() => setIsConfirmModalOpen(false)}
        doAction={() => dispatch(removeCommitmentRequest({path: transformedPath, id: item._id}))}
        item={item}
        isCommitment
      />
    </div>
  )
};

CommitmentItem.propTypes = {
  item: PropTypes.object,
  admin: PropTypes.object,
  curLang: PropTypes.string,
  path: PropTypes.any,
};

export default CommitmentItem;