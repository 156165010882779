import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { logoutRequest } from '../../actions/auth';
import { ReactComponent as LogoIconEn } from '../../assets/images/logo_ucep_site_eng.svg';
import { ReactComponent as LogoIcon } from '../../assets/images/logo_ucep_site.svg';
import { ReactComponent as LogOutIcon } from '../../assets/images/log-out.svg';
import { getTranslations } from '../../helpers/translations';
import { changeCurrentLang } from '../../actions/commitments';

const MENU_LINKS = [
  { link: `link_about_us`, name: `name_of_link_about_us` },
  { link: `link_our_team`, name: `name_of_link_our_team` },
  { link: `link_our_research`, name: `name_of_link_our_research` },
  { link: `link_news`, name: `name_of_link_news` },
  { link: `link_our_projects`, name: `name_of_link_our_projects` },
  { link: `link_navigator`, name: `name_of_link_navigator` },
  { link: `link_contacts`, name: `name_of_link_contacts` },
];

const HeaderContent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.auth.admin);
  const curLang = useSelector((state) => state.commitments.curLang);
  const showLogoutBtn = admin || null;

  const lang = {
    uk: 'UA',
    en: 'EN',
  };

  const submitLogout = () => {
    dispatch(logoutRequest());
  };

  const changeLanguage = (data) => {
    localStorage.setItem('curLang', data);
    dispatch(changeCurrentLang(data));
  };

  return (
    <div className="header__content max-container">
      <div className="header__logo logo">
        <a href={getTranslations('home_url', curLang)}>{curLang === 'en' ? <LogoIconEn /> : <LogoIcon />}</a>
      </div>

      <div className="header__btns-wrapper">
        <div className="header__lang-switcher__wrapper">
          <span className="header__lang-current">{lang[curLang]}</span>
          <ul>
            {Object.entries(lang).map((item) => (
              <li key={item[0]}>
                <button
                  type="button"
                  className={curLang === item[0] ? 'active' : ''}
                  onClick={() => changeLanguage(item[0])}
                >
                  {item[1]}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="header__hamburger hamburger">
          <button
            type="button"
            className={`hamburger__btn ${isMenuOpen ? 'active' : ''}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            title={`${isMenuOpen ? 'Close menu' : 'Open menu'}`}
          >
            <span />
          </button>
        </div>
        {showLogoutBtn && (
          <div className="border log-out-btn" onClick={submitLogout}>
            <LogOutIcon />
            <p>{getTranslations('logout', curLang)}</p>
          </div>
        )}
      </div>

      <div className={`header__menu menu ${isMenuOpen ? 'open' : ''}`}>
        <button type="button" className="close-menu" onClick={() => setIsMenuOpen(false)} title="Close menu" />
        <div className="menu__wrap">
          <ul>
            {MENU_LINKS.map((item) => (
              <li key={item.link}>
                <a href={getTranslations(item.link, curLang)}>{getTranslations(item.name, curLang)}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

HeaderContent.propTypes = {
  curLang: PropTypes.string,
  admin: PropTypes.object,
};

export default HeaderContent;
