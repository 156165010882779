import React, {useEffect, useState} from 'react';
import './Login.scss'
import Button from "@material-ui/core/Button";
import {getTranslations} from "../../helpers/translations";
import {useDispatch, useSelector} from "react-redux";
import {loginRequest, resetErrors} from "../../actions/auth";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

const Login = () => {

  const curLang = useSelector(state => state.commitments.curLang);
  const errors = useSelector(state => state.auth.upload.error);
  const pending = useSelector(state => state.auth.upload.pending);
  const admin = useSelector(state => state.auth.admin);
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    username: '',
    password: ''
  });

  useEffect(() => {
    if(admin) history.push('/');
  }, [admin, history]);

  const submitLogin = () => {
    dispatch(loginRequest(state))
  };

  const submitLoginError = () => {
    dispatch(resetErrors());
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  return (
    <div className='login'>
      <h2 className="heading">{getTranslations('login_title', curLang)}</h2>
      <ValidatorForm onSubmit={submitLogin} onError={submitLoginError} className='login__form'>
        <div className="login__field modal__field">
          {errors.username && <p className='error'>{errors.username[curLang]}</p>}
          <TextValidator
            value={state.username}
            name='username'
            type="text"
            label={getTranslations('login_username_placeholder', curLang)}
            variant="outlined"
            onChange={onHandleChange}
            validators={['required']}
            errorMessages={[getTranslations('required', curLang)]}
          />
        </div>
        <div className="login__field modal__field">
          {errors.password && <p className='error'>{errors.password[curLang]}</p>}
          <TextValidator
            value={state.password}
            name='password'
            type='password'
            label={getTranslations('login_password_placeholder', curLang)}
            variant="outlined"
            onChange={onHandleChange}
            validators={['required']}
            errorMessages={[getTranslations('required', curLang)]}
          />
        </div>
        <Button
          variant="contained"
          type='submit'
          className='button'
          disabled={pending}
        >
          {getTranslations('login_btn_title', curLang)}
        </Button>
      </ValidatorForm>
    </div>
  )
};

Login.propTypes = {
  errors: PropTypes.object,
  pending: PropTypes.bool,
  admin: PropTypes.object,
  curLang: PropTypes.string,
};

export default Login;