import React, {useState} from 'react';
import './LegacyActs.scss';
import {getTranslations} from "../../../helpers/translations";
import PropTypes from "prop-types";
import {ReactComponent as ExternalLink} from '../../../assets/images/external.svg';
import ActionButton from "../../../components/buttons/ActionButton/ActionButton";
import LegacyActModal from "../../../components/modals/LegacyActModal/LegacyActModal";
import {removeLegacyActRequest} from "../../../actions/commitments";
import ConfirmModal from "../../../components/modals/ConfirmModal/ConfirmModal";
import {useDispatch, useSelector} from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const LegacyActs = ({currentTask, curLang, admin, path}) => {

  const dispatch = useDispatch();
  const [isLegacyActModalOpen, setIsLegacyActModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [legacyAct, setLegacyAct] = useState({});
  const [value, setValue] = useState(0);
  const commitment_legacy_acts = useSelector(state => state.commitments.commitment_legacy_acts);

  const toggleLegacyActModal = (legacyAct) => {
    setLegacyAct(legacyAct);
    setIsLegacyActModalOpen(true);
  };

  const openConfirmModal = (legacyAct) => {
    setLegacyAct(legacyAct);
    setIsConfirmModalOpen(true)
  };

  const LegacyActItem = ({item}) => {
    return(
      <li className='border-b'>
        {item.link
          ? <a href={item.link} target='_blank' rel='noopener noreferrer'>
            {curLang === 'uk' ? item.name_ua : item.name_en}
            <ExternalLink/>
          </a>
          : <p className='text'><b>{curLang === 'uk' ? item.name_ua : item.name_en}</b></p>
        }
        <p>{curLang === 'uk' ? item.description_ua : item.description_en}</p>

        {admin && !!Object.keys(currentTask).length && (
          <div className="actionButton-wrap">
            <ActionButton edit doAction={() => toggleLegacyActModal(item)}/>
            <ActionButton remove doAction={() => openConfirmModal(item)}/>
          </div>
        )}
      </li>
    )
  };

  const MarkUp = () => {
    const legacy_acts = !!Object.keys(currentTask).length ? currentTask.legacy_acts : commitment_legacy_acts;

    return(
      <>
        <h3>{getTranslations('legacy_acts', curLang)}</h3>

        <h5 className='legacyActs__label border-b'>{getTranslations('in_force_plural', curLang)}</h5>
        <ul>
          {legacy_acts && legacy_acts.map(item => item.status === 'in_force'
            ? <LegacyActItem item={item} key={item._id} />
            : null
          )}
          <li className='legacyActs__empty'><p>No legacy acts yet.</p></li>
        </ul>

        <h5 className='legacyActs__label border-b'>{getTranslations('draft_plural', curLang)}</h5>
        <ul>
          {legacy_acts && legacy_acts.map(item => item.status === 'draft'
            ? <LegacyActItem item={item} key={item._id} />
            : null
          )}
          <li className='legacyActs__empty'><p>No legacy acts yet.</p></li>
        </ul>
      </>
    )
  };

  return (
    <div className="legacyActs border">
      {currentTask[`comment_${curLang === 'uk' ? 'ua': 'en'}`]
      ? <>
          <Tabs
            value={value}
            onChange={(e, newValue) => setValue(newValue)}

            variant="fullWidth"
          >
            <Tab label={getTranslations('legacy_acts', curLang)}/>
            <Tab label={getTranslations('expert_comment', curLang)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <MarkUp/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <p className='legacyActs__comment'>{currentTask[`comment_${curLang === 'uk' ? 'ua': 'en'}`]}</p>
          </TabPanel>
        </>
        : <MarkUp/>
      }

      <LegacyActModal
        titleSlug='edit_act'
        open={isLegacyActModalOpen}
        setIsModalOpen={() => setIsLegacyActModalOpen(false)}
        path={path}
        curLang={curLang}
        currentTask={currentTask}
        legacyAct={legacyAct}
      />

      <ConfirmModal
        titleSlug='legacy_act_title'
        curLang={curLang}
        open={isConfirmModalOpen}
        setIsModalOpen={() => setIsConfirmModalOpen(false)}
        doAction={() => dispatch(removeLegacyActRequest({
            path: path.join('/'),
            taskId: currentTask._id,
            legacyActId: legacyAct._id
          }))}
        item={legacyAct}
      />
    </div>
  )
};

LegacyActs.propTypes = {
  curLang: PropTypes.string,
  admin: PropTypes.object,
  currentTask: PropTypes.object,
  path: PropTypes.array,
  commitment_legacy_acts: PropTypes.array,
};

export default LegacyActs;