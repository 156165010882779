export const API_URL = process.env.REACT_APP_API_URL;

export const task_stages = [
	"implementation_not_started",
	"early_implementation",
	"advanced_implementation",
	"perfect_execution",
	"critical_discrepancy",
];

export const task_types = [
	"legal_approximation",
	"implementation",
];

export const legacy_acts_statuses = [
	"in_force",
	"draft",
];

export const sectors_number = [
	"",
	"I",
	"II",
	"III",
	"IV",
	"V",
	"VI",
];