import React, {useEffect, useState} from 'react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import {useDispatch, useSelector} from "react-redux";
import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {addLegacyActRequest, editLegacyActRequest} from "../../../actions/commitments";
import Button from "../../buttons/Button/Button";
import {getTranslations} from "../../../helpers/translations";
import {legacy_acts_statuses} from "../../../helpers/constants";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import {linkValidation} from "../../../helpers/utils";

const LegacyActModal = ({titleSlug, curLang, open, setIsModalOpen, path, legacyAct, currentTask}) => {
  const dispatch = useDispatch();
  const pending = useSelector(state => state.commitments.upload.pending);
  const error = useSelector(state => state.commitments.upload.error);

  const initialState = {
    name_ua: '',
    name_en: '',
    link: '',
    description_ua: '',
    description_en: '',
    status: '',
  };

  const [state, setState] = useState(legacyAct || initialState);

  useEffect(() => {
    if(legacyAct) setState(legacyAct);
  },[legacyAct]);

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      if(legacyAct) setState(legacyAct);
      else setState(initialState);
    }, 100);
  };

  const createCommitment = () => {
    const data = {
      path: path.join('/'),
      taskId: currentTask._id,
      body: state
    };
    if(legacyAct){
      data.legacyActId = legacyAct._id;
      dispatch(editLegacyActRequest(data, setIsModalOpen))
    }else {
      dispatch(addLegacyActRequest(data, closeModal))
    }
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  return (
    <Modal
      className='modal modal-wide'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className='modal__content'>
          <h3>{getTranslations(titleSlug, curLang)}</h3>
          <ValidatorForm onSubmit={createCommitment} className='modal__form'>
            <div className="modal__row">
              <div className="modal__field">
                <TextValidator
                  value={state.name_ua}
                  name='name_ua'
                  type="text"
                  label='Заголовок'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                />
              </div>
              <div className="modal__field">
                <TextValidator
                  value={state.name_en}
                  name='name_en'
                  type="text"
                  label='Title'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                />
              </div>
              <div className="modal__field">
                {error.link && state.link && <p className='error'>{error.link}</p>}
                <TextValidator
                  value={state.link || ''}
                  name='link'
                  type="text"
                  label={getTranslations('link', curLang)}
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={[`matchRegexp:${linkValidation}`]}
                  errorMessages={[getTranslations('invalid_link', curLang)]}
                />
              </div>
            </div>
            <div className="modal__row">
              <div className="modal__field">
                <TextValidator
                  value={state.description_ua}
                  multiline
                  rows={4}
                  name='description_ua'
                  type="text"
                  label='Опис'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                />
              </div>
              <div className="modal__field">
                <TextValidator
                  value={state.description_en}
                  multiline
                  rows={4}
                  name='description_en'
                  type="text"
                  label='Description'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                />
              </div>
            </div>
            <div className="modal__row">
              <div className="modal__field modal__field--only-child">
                <SelectValidator
                  value={state.status}
                  name='status'
                  type="text"
                  label={getTranslations('status', curLang)}
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null}
                  }}
                >
                  {legacy_acts_statuses.map((option, key) => (
                    <MenuItem key={key} value={option}>{getTranslations(option, curLang)}</MenuItem>
                  ))}
                </SelectValidator>
              </div>
            </div>
            <div className="modal__btns">
              <Button
                type="button"
                titleSlug='cancel'
                color='primary-inverse'
                doAction={closeModal}
              />
              <Button
                type='submit'
                titleSlug='save'
                color='primary'
                disabled={pending}
              />
            </div>
          </ValidatorForm>
        </div>
      </Fade>
    </Modal>
  )
};

LegacyActModal.propTypes = {
  titleSlug: PropTypes.string,
  open: PropTypes.bool,
  pending: PropTypes.bool,
  curLang: PropTypes.string,
  path: PropTypes.array,
  legacyAct: PropTypes.object,
  currentTask: PropTypes.object,
  error: PropTypes.object,
};

export default LegacyActModal;