import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getSearchedCommitmentsRequest } from '../../actions/commitments';
import { getTranslations } from "../../helpers/translations";
import CommitmentItem from '../../components/CommitmentItem/CommitmentItem';
import './Search.scss';

const Search = () => {
  const searchValue = useSelector(state => state.commitments.searchValue);
  const curLang = useSelector(state => state.commitments.curLang);
  const foundCommitments = useSelector(state => state.commitments.searchedCommitments);
  const admin = useSelector(state => state.auth.admin);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes('/search')) {
      const URLSearch = history.location.pathname.split('=').pop();
      dispatch(getSearchedCommitmentsRequest(URLSearch));
    }
  }, [history.location.pathname]); // eslint-disable-line

  return (
    <div className='searchedItems'>
      <h2>{getTranslations('search_results', curLang)}: {searchValue && `"${searchValue}"`}</h2>
      <p>{getTranslations('found_commitments', curLang)}: {foundCommitments.length}</p>
      <div className='searchedItems__wrap'>
        {foundCommitments?.map(item =>
          <CommitmentItem
            item={item}
            key={item.number}
            curLang={curLang}
            path={item.path}
            admin={admin}
          />
        )}
      </div>
    </div>
  )
};

export default Search;