import React from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {getTranslations} from "../../../helpers/translations";
import Modal from "@material-ui/core/Modal";
import Button from "../../buttons/Button/Button";

const ConfirmModal = ({titleSlug, curLang, open, setIsModalOpen, doAction, item, isCommitment}) => {

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = () => {
    doAction();
    closeModal();
  };

  return (
    <Modal
      className='modal'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className='modal__content'>
          <h3>
            {getTranslations('delete', curLang)}{' '}
            {getTranslations(titleSlug, curLang)} {isCommitment && <>№{item.number} </>}
            «{curLang === 'uk' ? item.name_ua : item.name_en}»?
          </h3>

          <div className="modal__btns">
            <Button
              type="button"
              titleSlug='cancel'
              color='primary-inverse'
              doAction={closeModal}
            />
            <Button
              type='submit'
              titleSlug='delete'
              color='primary'
              doAction={onSubmit}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  )
};

export default ConfirmModal;