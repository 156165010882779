import React from 'react';
import { useSelector } from 'react-redux';

import './Footer.scss';
import { ReactComponent as FacebookIcon } from '../../assets/images/facebook-blue.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter-logo.svg';
import { getTranslations } from '../../helpers/translations';

const Footer = () => {
  const curLang = useSelector((state) => state.commitments.curLang);

  return (
    <footer className="main-footer">
      <div className="footer-bottom max-container">
        <ul className="footer-social-icon">
          <li>
            <a href="https://www.facebook.com/UCEP.org.ua/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
          </li>

          <li>
            <a href="https://twitter.com/UCEP_UA" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
          </li>
        </ul>
        <div className="footer-info">
          © {getTranslations('footer_text', curLang)}, {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
