import React, {Fragment, useEffect, useState} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {useHistory} from "react-router-dom";
import './Sectors.scss'
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {getSectorsRequest} from "../../../actions/commitments";
import {getTranslations} from "../../../helpers/translations";
import Loader from "../../../components/Loader/Loader";
import {ExecutionWithCount} from "../../../components/Execution/Execution";
import {sectors_number, task_stages} from '../../../helpers/constants';

const Sectors = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [expanded, setExpanded] = useState([]);

  const toggleExpanded = (item) => {
    let newExpanded = [];
    if(expanded.includes(item)){
      newExpanded = expanded.slice().filter(el => el !== item)
    }else {
      newExpanded = [...expanded.slice(), item]
    }
    setExpanded(newExpanded);
  };


  useEffect(() => {
    dispatch(getSectorsRequest());
  }, [dispatch]);

  const sectors = useSelector(state => state.commitments.sectors);
  const sectors_year = useSelector(state => state.commitments.sectors_year);
  const curLang = useSelector(state => state.commitments.curLang);
  const loading = useSelector(state => state.commitments.loading);

  const treeTableWrapper = (taskTree, path, stickyLeft) => {
    return taskTree?.map((item, index) => {
      const sectionNumber = item[0].section ? item[0].section : 0;
      return (
        <li className={`section section-${sectionNumber}`} key={sectionNumber}>
          {stickyLeft && index !== 0 && (
            <div className='section__wrap'>
              <span>{getTranslations('chapter', curLang)} {sectors_number[sectionNumber]}</span>
            </div>
          )}
          <ul>{treeTable(item, path, stickyLeft)}</ul>
        </li>
      )
    });
  };

  const treeTable = (taskTree, path, stickyLeft) => {
    return taskTree?.map(task => {
      const nextLevel = task.subsectors ? task.subsectors : task.areas;
      return (
        <Fragment key={task.slug}>
          <TreeItem nodeId={task.slug}
            label={row(task, `${path}/${task.slug}`, stickyLeft)}
            onClick={() => toggleExpanded(task.slug)}
          >
            {treeTable(nextLevel, `${path}/${task.slug}`, stickyLeft)}
          </TreeItem>
        </Fragment>
      )
    });
  };

  const linkToCommitmentsPage = (path, nextLevel) => {
    if(!nextLevel && path !== '/overall_summary') history.push(`sectors${path}`)
  };

  const row = (task, path, stickyLeft) => {
    const nextLevel = task.subsectors ? task.subsectors : task.areas;
    return (
      <div className='sectors__row' onClick={() => linkToCommitmentsPage(path, nextLevel)}>
        {stickyLeft
          ?<>
            <Tooltip title={getTranslations(task.slug, curLang)} placement="top-start" arrow>
              <div className="cell-sticky">
                <div className='cell-name'>{getTranslations(task.slug, curLang)}</div>
              </div>
            </Tooltip>
            {sectors_year.slice(0, 1).map((item, index) => {
              const currentItem = task.summary?.find(el => el._id === item);
              return (
                <CellMarkUp currentItem={currentItem} item={item} task={task} key={index}/>
              )
            })}
           </>
          : sectors_year.slice(1).map((item, index) => {
              const currentItem = task.summary?.find(el => el._id === item);
              return (
                <CellMarkUp currentItem={currentItem} item={item} task={task} key={index}/>
              )
            })
        }
      </div>
    )
  };

  const CellMarkUp = ({currentItem, item, task}) => (
    <div className={`cell ${item === 'all'? 'cell-all-years' : ''}`} style={{ padding: 0 }}>
      {!currentItem
        ? <></>
        : <Tooltip
          placement="bottom-start"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
          title={
            <div className='sectors__tooltip'>
              <p>
                {getTranslations(task.slug, curLang)}
                <span>
                  ({isNaN(currentItem._id) ? getTranslations(currentItem._id, curLang) : currentItem._id})
                </span>
              </p>
              <ul className='stages-list'>
                {task_stages.map((item, index) =>
                  <li key={item} className={`stages-item stages-item-${index}`}>{getTranslations(item, curLang)}:
                    <b>{currentItem.stages && currentItem.stages[index] ? currentItem.stages[index] : 0}%</b>
                  </li>
                )}
              </ul>
            </div>
          }
        >
          <ExecutionWithCount item={currentItem}/>
        </Tooltip>
      }
    </div>
  );

  if(loading) return <Loader/>;

  return (
    <div className='sectors'>
      <div className="sectors__titles">
        <h3>{getTranslations('sectors', curLang)}</h3>
        <h3>{getTranslations('execution_and_commitments', curLang)}</h3>
      </div>
      <div className='sectors__body'>
        <div className="sectors__left">
          <div className="sectors__head">
            <div className='sectors__row'>
              <div className="cell"/>
              <div className="cell-sticky">
                <div className="cell-name"/>
              </div>
              {sectors_year.slice(0, 1).map((item, index ) => (
                <div className='cell' key={index}>{isNaN(item) ? getTranslations(item, curLang) : item}</div>
              ))}
            </div>
          </div>
          <TreeView
            defaultCollapseIcon={<span className='arrow'/>}
            defaultExpandIcon={<span className='arrow'/>}
            multiSelect
            expanded={expanded}
          >{treeTableWrapper(sectors, '', true)}</TreeView>
        </div>
        <div className="sectors__right">
          <div className='sectors__row'>
            {sectors_year.slice(1).map((item, index ) => (
              <div className='cell' key={index}>{isNaN(item) ? getTranslations(item, curLang) : item}</div>
            ))}
          </div>
          <TreeView
            expanded={expanded}
            defaultCollapseIcon={<span className='arrow'/>}
            defaultExpandIcon={<span className='arrow'/>}
            multiSelect
          >{treeTableWrapper(sectors, '')}</TreeView>
        </div>
      </div>
    </div>
  )
};

Sectors.propTypes = {
  sectors: PropTypes.array,
  sectors_year: PropTypes.array,
  curLang: PropTypes.string,
  loading: PropTypes.bool
};

export default Sectors;