import React from 'react';
import {Link} from "react-router-dom";
import {getTranslations} from "../../helpers/translations";
import './Breadcrumbs.scss'
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

const Breadcrumbs = ({path, curLang, linkToCommitments, commitment}) => {
  return(
    <ul className='breadcrumbs'>
      <li>
        <Link to='/'>{getTranslations('home', curLang)}</Link>
      </li>
      {path.map((item,key) =>
        <Tooltip title={getTranslations(item, curLang)} arrow key={key}>
          <li>
            {key !== path.length - 1
              ? <Link
                  to={`${linkToCommitments && key === path.length - 2 ? `/sectors/${path.slice(0, -1).join('/')}` : '/'}`}>
                  {getTranslations(item, curLang)}
                </Link>
              : <span>
                  {getTranslations(item, curLang)
                    || `${getTranslations('commitment_title', curLang)} №${commitment.number ? commitment.number : ''}`}
                </span>
            }
          </li>
        </Tooltip>
      )}
    </ul>
  )
};

Breadcrumbs.propTypes = {
  path: PropTypes.array,
  curLang: PropTypes.string,
};

export default Breadcrumbs;