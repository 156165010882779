import React from 'react'
import './Button.scss'
import {getTranslations} from "../../../helpers/translations";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const Button = ({titleSlug, withAddIcon, color, doAction, type, disabled}) => {
  const curLang = useSelector(state => state.commitments.curLang);
  return (
    <button type={type} className={`button ${color}`} onClick={doAction} disabled={disabled}>
      {withAddIcon && (
        <span className="addIcon"/>
      )}
      <span>{getTranslations(titleSlug, curLang)}</span>
    </button>
  )
};


Button.propTypes = {
  curLang: PropTypes.string,
};

export default Button;