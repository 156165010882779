import service from './service';
import axios from 'axios';
import {API_URL} from "../helpers/constants";

export const doLoginApi = data => {
  return service.post(
    `${API_URL}/login`, data
  )
};

export const doReafreshTokenApi = token => {
  return axios.post(
    `${API_URL}/token/refresh`, null, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).refresh_token}`
      }
    }
  )
};

export const doLogoutApi = () => {
  return service.post(
    `${API_URL}/logout/access`
  )
}