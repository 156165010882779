import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from "./auth";
import commitments from "./commitments";

const createRootReducer = (history) => combineReducers({
  auth,
  commitments,
  router: connectRouter(history),
});

export default createRootReducer;