import React, {useState} from 'react';
import {getTranslations} from "../../../helpers/translations";
import Collapse from "@material-ui/core/Collapse";
import PropTypes from "prop-types";
import './Tasks.scss'
import {changeCurrentTask, removeTaskRequest} from "../../../actions/commitments";
import {useDispatch} from "react-redux";
import TaskModal from "../../../components/modals/TaskModal/TaskModal";
import {task_types, task_stages} from "../../../helpers/constants";
import LegacyActs from "../LegacyActs/LegacyActs";
import ActionButton from "../../../components/buttons/ActionButton/ActionButton";
import ConfirmModal from "../../../components/modals/ConfirmModal/ConfirmModal";

const Tasks = ({tasks, curLang, path, currentTask, admin}) => {

  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [task, setTask] = useState({});

  const toggleTaskModal = (e, task) => {
    e.stopPropagation();
    setTask(task);
    setIsTaskModalOpen(true);
  };

  const toggleActions = (e, index) => {
    e.stopPropagation();
    if (selectedIndex === index) setSelectedIndex("");
    else setSelectedIndex(index)
  };

  const openConfirmModal = (e, task) => {
    e.stopPropagation();
    setTask(task);
    setIsConfirmModalOpen(true)
  };

  return (
    <>
      {tasks && !!tasks.length && (
        <div className='tasks'>
          <ul className='tasks__list border'>
            {tasks.map(item =>
              <li
                key={item._id}
                className={`tasks__item border-b ${item._id === currentTask._id ? 'active' : ''}`}
                onClick={() => dispatch(changeCurrentTask(item))}
              >
                <div className="tasks__wrap">
                  <p className='text'>{curLang === 'uk' ? item.name_ua : item.name_en}</p>
                  <div className="tasks__labels label">
                    <span className={`label__stage ${task_stages[item.stage]}`}>{getTranslations(task_stages[item.stage], curLang)}</span>
                    <span className='label__type'>{getTranslations(task_types[item.type], curLang)}</span>
                    {item.actions && !!item.actions.length && (
                      <span className={`border label__actions ${item._id === selectedIndex ? 'active' : ''}`}
                        onClick={(e) => toggleActions(e, item._id)}
                      >
                      {item._id === selectedIndex
                        ? getTranslations('hide_actions', curLang)
                        : getTranslations('show_actions', curLang)
                      }
                    </span>
                    )}
                  </div>
                  {admin && (
                    <div className="actionButton-wrap">
                      <ActionButton edit doAction={(e) => toggleTaskModal(e, item)}/>
                      <ActionButton remove doAction={(e) => openConfirmModal(e, item)}
                      />
                    </div>
                  )}
                </div>
                {item.actions && (
                  <Collapse in={item._id === selectedIndex} timeout="auto" unmountOnExit>
                    <ul className='tasks__collapse'>
                      {item.actions.map((item, index) =>
                        <li key={index}>{curLang === 'uk' ? item.name_ua : item.name_en}</li>
                      )}
                    </ul>
                  </Collapse>
                )}
              </li>
            )}
          </ul>
          <LegacyActs
            currentTask={currentTask}
            curLang={curLang}
            admin={admin}
            path={path}
          />
        </div>
      )}

      <TaskModal
        titleSlug='edit_task'
        open={isTaskModalOpen}
        setIsModalOpen={() => setIsTaskModalOpen(false)}
        path={path}
        curLang={curLang}
        task={task}
      />

      <ConfirmModal
        titleSlug='task_title'
        curLang={curLang}
        open={isConfirmModalOpen}
        setIsModalOpen={() => setIsConfirmModalOpen(false)}
        doAction={() => dispatch(removeTaskRequest({path: path.join('/'), id: task._id}))}
        item={task}
      />
    </>
  )
};

Tasks.propTypes = {
  tasks: PropTypes.array,
  curLang: PropTypes.string,
  admin: PropTypes.object,
  path: PropTypes.array,
  currentTask: PropTypes.object,
};

export default Tasks;