import {defaultActionCreator} from "./actionCreator";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_FAILED = 'LOGOUT_REQUEST_FAILE';

export const loginRequest = defaultActionCreator(LOGIN_REQUEST, 'data');
export const loginRequestSuccess = defaultActionCreator(LOGIN_REQUEST_SUCCESS, 'data');
export const loginRequestFailed = defaultActionCreator(LOGIN_REQUEST_FAILED, 'error');
export const logoutRequest = defaultActionCreator(LOGOUT_REQUEST, 'data');
export const logoutRequestSuccess = defaultActionCreator(LOGOUT_REQUEST_SUCCESS, 'data');
export const logoutRequestFailed = defaultActionCreator(LOGOUT_REQUEST_FAILED, 'error');

export const RESET_ERRORS = 'RESET_ERRORS';
export const resetErrors = defaultActionCreator(RESET_ERRORS, 'data');