import React, {useEffect, useState} from 'react';
import {
  changeCurrentYear,
  getCommitmentsRequest,
  getCommitmentsSummaryRequest,
  resetCommitmentsData
} from "../../actions/commitments";
import {useDispatch, useSelector} from "react-redux";
import {getTranslations} from "../../helpers/translations";
import CommitmentItem from "../../components/CommitmentItem/CommitmentItem";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import './Commitments.scss'
import PropTypes from "prop-types";
import Button from "../../components/buttons/Button/Button";
import CommitmentModal from "../../components/modals/CommitmentModal/CommitmentModal";
import Execution, {ExecutionWithCount} from "../../components/Execution/Execution";
import {task_stages} from '../../helpers/constants';
import InfiniteScroll from 'react-infinite-scroll-component';

const Commitments = (props) => {

  const dispatch = useDispatch();
  const curLang = useSelector(state => state.commitments.curLang);
  const commitments = useSelector(state => state.commitments.commitments);
  const years_summary = useSelector(state => state.commitments.years_summary);
  const loading = useSelector(state => state.commitments.loading);
  const admin = useSelector(state => state.auth.admin);
  const currentYear = useSelector(state => state.commitments.currentYear);
  const queryRequest = useSelector(state => state.commitments.queryRequest);
  const path = props.location.pathname.split('/').slice(2);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = {
    path: path.join('/'),
    query: queryRequest
  };

  useEffect(() => {
    dispatch(getCommitmentsSummaryRequest(data.path));
    dispatch(getCommitmentsRequest(data));
  }, []); // eslint-disable-line

  useEffect(() => {
    return () => {
      dispatch(resetCommitmentsData());
    };
  }, []);// eslint-disable-line

  const loadMore = () => {
    if(currentYear._id !== 'all') data.query.year = currentYear._id;
    dispatch(getCommitmentsRequest(data));
  };

  const switchYear = (item) => {
    dispatch(changeCurrentYear(item));
    dispatch(resetCommitmentsData());
    if(item._id !== 'all') data.query.year = item._id;
    data.query.offset = 0;
    dispatch(getCommitmentsRequest(data));
  };

  return (
    <>
      <Breadcrumbs path={path} curLang={curLang}/>
      <div className="heading-wrap">
        <h2 className="heading">{getTranslations(path[path.length-1], curLang)}</h2>
        {admin && (
          <Button
            withAddIcon
            type='button'
            titleSlug='add_commitment'
            color='primary'
            doAction={() => setIsModalOpen(true)}
          />
        )}
      </div>
      {loading
        ? <div className='loader'/>
        : !!Object.keys(currentYear).length
          ? <div className="commitments">
            <ul className='commitments__years'>
              {years_summary.map(item =>
                <li
                  className={`border-b ${currentYear._id === item._id ? 'active' : ''}`}
                  key={item._id}
                  onClick={() => switchYear(item)}
                >
                  {isNaN(item._id) ? getTranslations(item._id, curLang) : item._id}
                  <ExecutionWithCount item={item}/>
                </li>
              )}
            </ul>
            {loading
              ? <div className='loader'/>
              : <div className="commitments__items border">
                <div className="commitments__info">
                  <div className="commitments__left">
                    <h3>
                      {getTranslations('commitments_title', curLang)}{' '}
                      {isNaN(currentYear._id)
                        ? getTranslations(`${currentYear._id}_years`, curLang)
                        : <>
                          {getTranslations('for', curLang)} {currentYear._id} {getTranslations('year', curLang)}
                        </>
                      }
                    </h3>
                    <ul className='stages-list'>
                      {task_stages.map((item, index) =>
                        <li key={item} className={`stages-item stages-item-${index}`}>{getTranslations(item, curLang)}:
                          <b>{currentYear.stages[index] ? currentYear.stages[index] : 0}%</b>
                        </li>
                      )}
                    </ul>
                  </div>
                  <Execution curLang={curLang} percentage={currentYear.execution}/>
                </div>

                <InfiniteScroll
                  dataLength={commitments.length}
                  next={loadMore}
                  hasMore={currentYear.count > commitments.length}
                  loader={<div className="loader" />}
                >
                  {commitments && commitments.map(item =>
                    <CommitmentItem
                      item={item}
                      key={item.number}
                      curLang={curLang}
                      path={path}
                      admin={admin}
                    />
                  )}
                </InfiniteScroll>
              </div>
            }
          </div>
          : <></>
      }

      <CommitmentModal
        titleSlug='new_commitment'
        curLang={curLang}
        open={isModalOpen}
        setIsModalOpen={() => setIsModalOpen(false)}
        path={path}
      />
    </>
  )
};

Commitments.propTypes = {
  commitments: PropTypes.array,
  years_summary: PropTypes.array,
  admin: PropTypes.object,
  curLang: PropTypes.string,
  loading: PropTypes.bool,
  currentYear: PropTypes.object,
  queryRequest: PropTypes.object,
};

export default Commitments;