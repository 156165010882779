import React, {useEffect, useState} from 'react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import {useDispatch, useSelector} from "react-redux";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {getFullDate, linkValidation} from "../../../helpers/utils";
import {
  addAssigneeRequest,
  addCommitmentRequest,
  editCommitmentRequest,
  resetCommitmentErrors
} from "../../../actions/commitments";
import DatePicker from "../../DatePicker/DatePicker";
import Button from "../../buttons/Button/Button";
import {getTranslations} from "../../../helpers/translations";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";

const CommitmentModal = ({titleSlug, curLang, open, setIsModalOpen, path, commitment}) => {
  const dispatch = useDispatch();
  const assignees = useSelector(state => state.commitments.assignees);
  const pending = useSelector(state => state.commitments.upload.pending);
  const error = useSelector(state => state.commitments.upload.error);
  const transformedPath = typeof path === 'string' ? path : path.join('/');

  const initialState = {
    name_ua: '',
    name_en: '',
    description_ua: '',
    description_en: '',
    year: null,
    assignees: [],
    deadline: null,
    eu_acts: [],
  };

  const [state, setState] = useState(commitment || initialState);
  const [isAddAssigneeOpen, setIsAddAssigneeOpen] = useState(false);

  const [assigneeState, setAssigneeState] = useState({
    name_ua: '',
    name_en: '',
  });

  const newAct = {
    name_ua: '',
    name_en: '',
    link: ''
  };

  useEffect(() => {
    if(commitment) setState({
      ...commitment,
      year: yearForDatePicker()
    });
  },[commitment]); // eslint-disable-line

  const changeDeadline = (date) => {
    setState({...state, deadline: getFullDate(date)})
  };

  const yearForDatePicker = () => {
    return !isNaN(commitment.year)
      ? commitment.year
        ? new Date(commitment.year.toString())
        : commitment.year
      : null
  };

  const changeYear = (date) => {
    setState({...state, year: date})
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(resetCommitmentErrors());
    setTimeout(() => {
      setIsAddAssigneeOpen(false);
      if(commitment) setState({
        ...commitment,
        year: yearForDatePicker()
      });
      else setState(initialState);
    }, 100);
  };

  const createCommitment = () => {
    if(isAddAssigneeOpen){
      dispatch(addAssigneeRequest(assigneeState, addNewAssignee));
      setIsAddAssigneeOpen(false);
    }else {
      const data = {
        path: transformedPath,
        body: {
          ...state,
          year: state.year ? state.year.getFullYear() : null,
          assignees: state.assignees.map(item => item._id)
        }
      };
      if(commitment){
        if(!data.path.includes(commitment._id)){
          data.realPath = data.path;
          data.path += `/${commitment._id}`;
        }
        dispatch(editCommitmentRequest(data, setIsModalOpen));
      }else {
        dispatch(addCommitmentRequest(data, closeModal))
      }
    }
  };

  const submitError = () => {
    dispatch(resetCommitmentErrors());
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  const changeEUActs = (e, index) => {
    const eu_acts = state.eu_acts.slice();
    eu_acts[index] = {...eu_acts[index], [e.target.name]: e.target.value};
    setState({...state, eu_acts })
  };

  const changeAssignee = (e, value) => {
    setState({...state, assignees: value })
  };

  const addNewAssignee = (newAssignee) => {
    setState({...state, assignees: state.assignees ? [...state.assignees, newAssignee] : [newAssignee]});
    setAssigneeState({
      name_ua: '',
      name_en: '',
    })
  };

  let validationProps = {
    validators: ['required'],
    errorMessages: [getTranslations('required', curLang)],
  };
  let validationLinkProps = {
    validators: ['required', `matchRegexp:${linkValidation}`],
    errorMessages: [getTranslations('required', curLang), getTranslations('invalid_link', curLang)],
  };

  if(isAddAssigneeOpen){
    validationLinkProps = validationProps = {
      validators: [],
      errorMessages: [],
      disabled: true
    }
  }

  return (
    <Modal
      className='modal modal-wide'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className='modal__content'>
          <h3>{getTranslations(titleSlug, curLang)}</h3>
          <ValidatorForm
            onSubmit={createCommitment}
            className={`modal__form ${isAddAssigneeOpen ? 'hideErrors' : ''}`}
            onError={submitError}
          >
            <div className="modal__row">
              <div className="modal__field">
                <TextValidator
                  value={state.name_ua}
                  name='name_ua'
                  type="text"
                  label='Заголовок'
                  variant="outlined"
                  onChange={onHandleChange}
                  {...validationProps}
                />
              </div>
              <div className="modal__field">
                <TextValidator
                  value={state.name_en}
                  name='name_en'
                  type="text"
                  label='Title'
                  variant="outlined"
                  onChange={onHandleChange}
                  {...validationProps}
                />
              </div>
            </div>
            <div className="modal__row">
              <div className="modal__field">
                <TextValidator
                  value={state.description_ua}
                  multiline
                  rows={4}
                  name='description_ua'
                  type="text"
                  label='Опис'
                  variant="outlined"
                  onChange={onHandleChange}
                  {...validationProps}
                />
              </div>
              <div className="modal__field">
                <TextValidator
                  value={state.description_en}
                  multiline
                  rows={4}
                  name='description_en'
                  type="text"
                  label='Description'
                  variant="outlined"
                  onChange={onHandleChange}
                  {...validationProps}
                />
              </div>
            </div>
            {isAddAssigneeOpen
              ? <>
                <div className="modal__row modal__row--assignee">
                  <div className="modal__field">
                    <TextValidator
                      value={assigneeState.name_ua}
                      name='name_ua'
                      type="text"
                      label='Додати Виконавця'
                      variant="outlined"
                      onChange={(e) => setAssigneeState({...assigneeState, name_ua: e.target.value})}
                      validators={['required']}
                      errorMessages={[getTranslations('required', curLang)]}
                    />
                  </div>
                  <div className="modal__field">
                    <TextValidator
                      value={assigneeState.name_en}
                      name='name_en'
                      type="text"
                      label='Add Assignee'
                      variant="outlined"
                      onChange={(e) => setAssigneeState({...assigneeState, name_en: e.target.value})}
                      validators={['required']}
                      errorMessages={[getTranslations('required', curLang)]}
                    />
                  </div>
                </div>
                <div className="modal__assignee-btns">
                  <Button
                    type="button"
                    titleSlug='cancel'
                    color='primary-inverse'
                    doAction={() => setIsAddAssigneeOpen(false)}
                  />
                  <Button
                    titleSlug='save_assignee'
                    color='primary'
                    disabled={pending}
                    type='submit'
                  />
                </div>
              </>
              : <div className="modal__row">
                <div className="modal__field modal__field--only-child d-flex align-center">
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={assignees}
                    name='assignees'
                    getOptionLabel={(option) => {
                      if(option){
                        return curLang === 'uk' ? option.name_ua : option.name_en
                      }
                      return ''
                    }}
                    onChange={changeAssignee}
                    value={state.assignees}
                    getOptionSelected={(option, value) => value._id === option._id}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label={getTranslations('assignee', curLang)}
                        variant="outlined"
                        value={state.assignees}
                        validators={['required']}
                        errorMessages={[getTranslations('required', curLang)]}
                      />
                    )}
                  />
                  <div className="add-new-field">
                    <span className='add' onClick={() => setIsAddAssigneeOpen(true)}/>
                  </div>
                </div>
              </div>
            }
            <div className="modal__row modal__field--only-child">
              <div className="modal__field">
                <DatePicker
                  selected={state.deadline}
                  name='deadline'
                  label={getTranslations('deadline', curLang)}
                  onChange={changeDeadline}
                  format="yyyy/MM/dd"
                  curLang={curLang}
                  {...validationProps}
                />
              </div>
              <div className="modal__field">
                <DatePicker
                  selected={state.year}
                  name='year'
                  label={getTranslations('year_capital', curLang)}
                  onChange={changeYear}
                  views={["year"]}
                  format="yyyy"
                  curLang={curLang}
                  {...validationProps}
                />
              </div>
            </div>
            <div className="modal__dynamic-row">
              <h5>{getTranslations('add_eu_acts', curLang)}
                <div className="add-new-field">
                  <span
                    className='add'
                    onClick={() => setState({...state, eu_acts: state.eu_acts ? [...state.eu_acts, newAct] : [newAct]})}
                  />
                </div>
              </h5>
              {state.eu_acts && state.eu_acts.map((item, index) =>
                <div className="modal__row modal__row--actions" key={index}>
                  <div className="modal__field">
                    <TextValidator
                      value={item.name_ua}
                      name='name_ua'
                      type="text"
                      label='Заголовок'
                      variant="outlined"
                      onChange={(e) => changeEUActs(e, index)}
                      {...validationProps}
                    />
                  </div>
                  <div className="modal__field">
                    <TextValidator
                      value={item.name_en}
                      name='name_en'
                      type="text"
                      label='Title'
                      variant="outlined"
                      onChange={(e) => changeEUActs(e, index)}
                      {...validationProps}
                    />
                  </div>
                  <div className="modal__field mr-0">
                    {error.eu_acts && item.link && <p className='error'>{error.eu_acts}</p>}
                    <TextValidator
                      value={item.link}
                      name='link'
                      type="text"
                      label={getTranslations('link', curLang)}
                      variant="outlined"
                      onChange={(e) => changeEUActs(e, index)}
                      {...validationLinkProps}
                    />
                  </div>
                  <div className="add-new-field">
                  <span
                    className='remove'
                    onClick={() => setState({
                      ...state, eu_acts: state.eu_acts.filter((el, ind) => ind !== index)
                    })}
                  />
                  </div>
                </div>
              )}
            </div>
            <div className="modal__btns">
              <Button
                type="button"
                titleSlug='cancel'
                color='primary-inverse'
                doAction={closeModal}
              />
              <Button
                type='submit'
                titleSlug='save'
                color='primary'
                disabled={pending || isAddAssigneeOpen}
              />
            </div>
          </ValidatorForm>
        </div>
      </Fade>
    </Modal>
  )
};

CommitmentModal.propTypes = {
  titleSlug: PropTypes.string,
  open: PropTypes.bool,
  pending: PropTypes.bool,
  curLang: PropTypes.string,
  path: PropTypes.any,
  error: PropTypes.object,
  assignees: PropTypes.array,
};

export default CommitmentModal;