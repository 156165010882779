import React, {useEffect, useState} from 'react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import {useDispatch, useSelector} from "react-redux";
import {addTaskRequest, editTaskRequest} from "../../../actions/commitments";
import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import {task_stages, task_types} from '../../../helpers/constants'
import {getTranslations} from "../../../helpers/translations";
import Button from "../../buttons/Button/Button";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const TaskModal = ({titleSlug, open, setIsModalOpen, path, curLang, task}) => {
  const dispatch = useDispatch();
  const pending = useSelector(state => state.commitments.upload.pending);

  const initialState = {
    name_ua: '',
    name_en: '',
    stage: '',
    type: '',
    comment_ua: '',
    comment_en: '',
    actions: []
  };

  const [state, setState] = useState(task || initialState);
  const newAct = {
    name_ua: '',
    name_en: '',
  };

  useEffect(() => {
    if(task) setState(task);
  },[task]);

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      if(task) setState(task);
      else setState(initialState);
    }, 100);
  };

  const createCommitment = () => {
    const data = {
      path: path.join('/'),
      body: state
    };
    if(task){
      data.id = task._id;
      dispatch(editTaskRequest(data, setIsModalOpen));
    }else {
      dispatch(addTaskRequest(data, closeModal))
    }
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  const changeActionActs = (e, index) => {
    const actions = state.actions.slice();
    actions[index] = {...actions[index], [e.target.name]: e.target.value};
    setState({...state, actions })
  };

  const setSelectValue = (e, arr) => {
    setState({...state, [e.target.name]: arr.indexOf(e.target.value)})
  };

  return (
    <Modal
      className='modal modal-wide'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className='modal__content'>
          <h3>{getTranslations(titleSlug, curLang)}</h3>
          <ValidatorForm onSubmit={createCommitment} className='modal__form'>
            <div className="modal__row">
              <div className="modal__field">
                <TextValidator
                  value={state.name_ua}
                  name='name_ua'
                  type="text"
                  label='Заголовок'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                />
              </div>
              <div className="modal__field">
                <TextValidator
                  value={state.name_en}
                  name='name_en'
                  type="text"
                  label='Title'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                />
              </div>
            </div>
            <div className="modal__row">
              <div className="modal__field modal__field--only-child">
                <SelectValidator
                  value={task_stages[state.stage] || ''}
                  name='stage'
                  type="text"
                  label={getTranslations('task_status', curLang)}
                  variant="outlined"
                  onChange={(e) => setSelectValue(e, task_stages)}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null}
                  }}
                >
                  {task_stages.map((option, key) => (
                    <MenuItem key={key} value={option}>{getTranslations(option, curLang)}</MenuItem>
                  ))}
                </SelectValidator>
              </div>
            </div>
            <div className="modal__row">
              <div className="modal__field modal__field--only-child">
                <SelectValidator
                  value={task_types[state.type] || ''}
                  name='type'
                  type="text"
                  label={getTranslations('task_type', curLang)}
                  variant="outlined"
                  onChange={(e) => setSelectValue(e, task_types)}
                  validators={['required']}
                  errorMessages={[getTranslations('required', curLang)]}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null}
                  }}
                >
                  {task_types.map((option, key) => (
                    <MenuItem key={key} value={option}>{getTranslations(option, curLang)}</MenuItem>
                  ))}
                </SelectValidator>
              </div>
            </div>
            <div className="modal__row">
              <div className="modal__field">
                <TextField
                  value={state.comment_ua}
                  multiline
                  rows={4}
                  name='comment_ua'
                  type="text"
                  label='Коментар'
                  variant="outlined"
                  onChange={onHandleChange}
                />
              </div>
              <div className="modal__field">
                <TextField
                  value={state.comment_en}
                  multiline
                  rows={4}
                  name='comment_en'
                  type="text"
                  label='Comment'
                  variant="outlined"
                  onChange={onHandleChange}
                />
              </div>
            </div>
            <div className="modal__dynamic-row">
              <h5>{getTranslations('add_action', curLang)}
                <div className="add-new-field">
                  <span
                    className='add'
                    onClick={() => setState({...state, actions: state.actions ? [...state.actions, newAct] : [newAct]})}
                  />
                </div>
              </h5>
              {state.actions && state.actions.map((item, index) =>
                <div className="modal__row modal__row--actions" key={index}>
                  <div className="modal__field modal__field-grow">
                    <TextValidator
                      value={item.name_ua}
                      name='name_ua'
                      type="text"
                      label='Заголовок'
                      variant="outlined"
                      onChange={(e) => changeActionActs(e, index)}
                      validators={['required']}
                      errorMessages={[getTranslations('required', curLang)]}
                    />
                  </div>
                  <div className="modal__field mr-0">
                    <TextValidator
                      value={item.name_en}
                      name='name_en'
                      type="text"
                      label='Title'
                      variant="outlined"
                      onChange={(e) => changeActionActs(e, index)}
                      validators={['required']}
                      errorMessages={[getTranslations('required', curLang)]}
                    />
                  </div>
                  <div className="add-new-field">
                  <span
                    className='remove'
                    onClick={() => setState({
                      ...state, actions: state.actions.filter((el, ind) => ind !== index)
                    })}
                  />
                  </div>
                </div>
              )}
            </div>
            <div className="modal__btns">
              <Button
                type="button"
                titleSlug='cancel'
                color='primary-inverse'
                doAction={closeModal}
              />
              <Button
                type='submit'
                titleSlug='save'
                color='primary'
                disabled={pending}
              />
            </div>
          </ValidatorForm>
        </div>
      </Fade>
    </Modal>
  )
};

TaskModal.propTypes = {
  titleSlug: PropTypes.string,
  open: PropTypes.bool,
  pending: PropTypes.bool,
  curLang: PropTypes.string,
  path: PropTypes.array,
  task: PropTypes.object,
};

export default TaskModal;