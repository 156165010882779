import {defaultActionCreator} from "./actionCreator";

export const CHANGE_CURRENT_LANG = 'CHANGE_CURRENT_LANG';
export const changeCurrentLang = defaultActionCreator(CHANGE_CURRENT_LANG, 'data');

export const GET_SEARCHED_COMMITMETS_REQUEST = 'GET_SEARCHED_COMMITMETS_REQUEST';
export const GET_SEARCHED_COMMITMETS_REQUEST_SUCCESS = 'GET_SEARCHED_COMMITMETS_REQUEST_SUCCESS';
export const GET_SEARCHED_COMMITMETS_REQUEST_FAILD = 'GET_SEARCHED_COMMITMETS_REQUEST_FAILD';

export const getSearchedCommitmentsRequest = defaultActionCreator(GET_SEARCHED_COMMITMETS_REQUEST, 'data');
export const getSearchedCommitmentsRequestSuccess = defaultActionCreator(GET_SEARCHED_COMMITMETS_REQUEST_SUCCESS, 'data');
export const getSearchedCommitmentsRequestFaild = defaultActionCreator(GET_SEARCHED_COMMITMETS_REQUEST_FAILD, 'data');

export const GET_SECTORS_REQUEST = 'GET_SECTORS_REQUEST';
export const GET_SECTORS_REQUEST_SUCCESS = 'GET_SECTORS_REQUEST_SUCCESS';
export const GET_SECTORS_REQUEST_FAILED = 'GET_SECTORS_REQUEST_FAILED';

export const getSectorsRequest = defaultActionCreator(GET_SECTORS_REQUEST, 'data');
export const getSectorsRequestSuccess = defaultActionCreator(GET_SECTORS_REQUEST_SUCCESS, 'data');
export const getSectorsRequestFailed = defaultActionCreator(GET_SECTORS_REQUEST_FAILED, 'error');

export const GET_COMMITMENTS_REQUEST = 'GET_COMMITMENTS_REQUEST';
export const GET_COMMITMENTS_REQUEST_SUCCESS = 'GET_COMMITMENTS_REQUEST_SUCCESS';
export const GET_COMMITMENTS_REQUEST_FAILED = 'GET_COMMITMENTS_REQUEST_FAILED';

export const getCommitmentsRequest = defaultActionCreator(GET_COMMITMENTS_REQUEST, 'data');
export const getCommitmentsRequestSuccess = defaultActionCreator(GET_COMMITMENTS_REQUEST_SUCCESS, 'data');
export const getCommitmentsRequestFailed = defaultActionCreator(GET_COMMITMENTS_REQUEST_FAILED, 'error');

export const GET_COMMITMENTS_SUMMARY_REQUEST = 'GET_COMMITMENTS_SUMMARY_REQUEST';
export const GET_COMMITMENTS_SUMMARY_REQUEST_SUCCESS = 'GET_COMMITMENTS_SUMMARY_REQUEST_SUCCESS';
export const GET_COMMITMENTS_SUMMARY_REQUEST_FAILED = 'GET_COMMITMENTS_SUMMARY_REQUEST_FAILED';

export const getCommitmentsSummaryRequest = defaultActionCreator(GET_COMMITMENTS_SUMMARY_REQUEST, 'data');
export const getCommitmentsSummaryRequestSuccess = defaultActionCreator(GET_COMMITMENTS_SUMMARY_REQUEST_SUCCESS, 'data');
export const getCommitmentsSummaryRequestFailed = defaultActionCreator(GET_COMMITMENTS_SUMMARY_REQUEST_FAILED, 'error');

export const GET_COMMITMENT_DETAIL_REQUEST = 'GET_COMMITMENT_DETAIL_REQUEST';
export const GET_COMMITMENT_DETAIL_REQUEST_SUCCESS = 'GET_COMMITMENT_DETAIL_REQUEST_SUCCESS';
export const GET_COMMITMENT_DETAIL_REQUEST_FAILED = 'GET_COMMITMENT_DETAIL_REQUEST_FAILED';

export const getCommitmentDetailRequest = defaultActionCreator(GET_COMMITMENT_DETAIL_REQUEST, 'data');
export const getCommitmentDetailRequestSuccess = defaultActionCreator(GET_COMMITMENT_DETAIL_REQUEST_SUCCESS, 'data');
export const getCommitmentDetailRequestFailed = defaultActionCreator(GET_COMMITMENT_DETAIL_REQUEST_FAILED, 'error');

export const GET_COMMITMENT_REQUEST = 'GET_COMMITMENT_REQUEST';
export const GET_COMMITMENT_REQUEST_SUCCESS = 'GET_COMMITMENT_REQUEST_SUCCESS';
export const GET_COMMITMENT_REQUEST_FAILED = 'GET_COMMITMENT_REQUEST_FAILED';

export const getCommitmentRequest = defaultActionCreator(GET_COMMITMENT_REQUEST, 'data', 'callback');
export const getCommitmentRequestSuccess = defaultActionCreator(GET_COMMITMENT_REQUEST_SUCCESS, 'data');
export const getCommitmentRequestFailed = defaultActionCreator(GET_COMMITMENT_REQUEST_FAILED, 'error');

export const ADD_COMMITMENT_REQUEST = 'ADD_COMMITMENT_REQUEST';
export const ADD_COMMITMENT_REQUEST_SUCCESS = 'ADD_COMMITMENT_REQUEST_SUCCESS';
export const ADD_COMMITMENT_REQUEST_FAILED = 'ADD_COMMITMENT_REQUEST_FAILED';

export const addCommitmentRequest = defaultActionCreator(ADD_COMMITMENT_REQUEST, 'data', 'callback');
export const addCommitmentRequestSuccess = defaultActionCreator(ADD_COMMITMENT_REQUEST_SUCCESS, 'data');
export const addCommitmentRequestFailed = defaultActionCreator(ADD_COMMITMENT_REQUEST_FAILED, 'error');

export const EDIT_COMMITMENT_REQUEST = 'EDIT_COMMITMENT_REQUEST';
export const EDIT_COMMITMENT_REQUEST_SUCCESS = 'EDIT_COMMITMENT_REQUEST_SUCCESS';
export const EDIT_COMMITMENT_REQUEST_FAILED = 'EDIT_COMMITMENT_REQUEST_FAILED';

export const editCommitmentRequest = defaultActionCreator(EDIT_COMMITMENT_REQUEST, 'data', 'callback');
export const editCommitmentRequestSuccess = defaultActionCreator(EDIT_COMMITMENT_REQUEST_SUCCESS, 'data');
export const editCommitmentRequestFailed = defaultActionCreator(EDIT_COMMITMENT_REQUEST_FAILED, 'error');

export const REMOVE_COMMITMENT_REQUEST = 'REMOVE_COMMITMENT_REQUEST';
export const REMOVE_COMMITMENT_REQUEST_SUCCESS = 'REMOVE_COMMITMENT_REQUEST_SUCCESS';
export const REMOVE_COMMITMENT_REQUEST_FAILED = 'REMOVE_COMMITMENT_REQUEST_FAILED';

export const removeCommitmentRequest = defaultActionCreator(REMOVE_COMMITMENT_REQUEST, 'data');
export const removeCommitmentRequestSuccess = defaultActionCreator(REMOVE_COMMITMENT_REQUEST_SUCCESS, 'data');
export const removeCommitmentRequestFailed = defaultActionCreator(REMOVE_COMMITMENT_REQUEST_FAILED, 'error');

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_REQUEST_SUCCESS = 'ADD_TASK_REQUEST_SUCCESS';
export const ADD_TASK_REQUEST_FAILED = 'ADD_TASK_REQUEST_FAILED';

export const addTaskRequest = defaultActionCreator(ADD_TASK_REQUEST, 'data', 'callback');
export const addTaskRequestSuccess = defaultActionCreator(ADD_TASK_REQUEST_SUCCESS, 'data');
export const addTaskRequestFailed = defaultActionCreator(ADD_TASK_REQUEST_FAILED, 'error');

export const EDIT_TASK_REQUEST = 'EDIT_TASK_REQUEST';
export const EDIT_TASK_REQUEST_SUCCESS = 'EDIT_TASK_REQUEST_SUCCESS';
export const EDIT_TASK_REQUEST_FAILED = 'EDIT_TASK_REQUEST_FAILED';

export const editTaskRequest = defaultActionCreator(EDIT_TASK_REQUEST, 'data', 'callback');
export const editTaskRequestSuccess = defaultActionCreator(EDIT_TASK_REQUEST_SUCCESS, 'data');
export const editTaskRequestFailed = defaultActionCreator(EDIT_TASK_REQUEST_FAILED, 'error');

export const REMOVE_TASK_REQUEST = 'REMOVE_TASK_REQUEST';
export const REMOVE_TASK_REQUEST_SUCCESS = 'REMOVE_TASK_REQUEST_SUCCESS';
export const REMOVE_TASK_REQUEST_FAILED = 'REMOVE_TASK_REQUEST_FAILED';

export const removeTaskRequest = defaultActionCreator(REMOVE_TASK_REQUEST, 'data');
export const removeTaskRequestSuccess = defaultActionCreator(REMOVE_TASK_REQUEST_SUCCESS, 'data');
export const removeTaskRequestFailed = defaultActionCreator(REMOVE_TASK_REQUEST_FAILED, 'error');

export const ADD_LEGACY_ACT_REQUEST = 'ADD_LEGACY_ACT_REQUEST';
export const ADD_LEGACY_ACT_REQUEST_SUCCESS = 'ADD_LEGACY_ACT_REQUEST_SUCCESS';
export const ADD_LEGACY_ACT_REQUEST_FAILED = 'ADD_LEGACY_ACT_REQUEST_FAILED';

export const addLegacyActRequest = defaultActionCreator(ADD_LEGACY_ACT_REQUEST, 'data', 'callback');
export const addLegacyActRequestSuccess = defaultActionCreator(ADD_LEGACY_ACT_REQUEST_SUCCESS, 'data');
export const addLegacyActRequestFailed = defaultActionCreator(ADD_LEGACY_ACT_REQUEST_FAILED, 'error');

export const EDIT_LEGACY_ACT_REQUEST = 'EDIT_LEGACY_ACT_REQUEST';
export const EDIT_LEGACY_ACT_REQUEST_SUCCESS = 'EDIT_LEGACY_ACT_REQUEST_SUCCESS';
export const EDIT_LEGACY_ACT_REQUEST_FAILED = 'EDIT_LEGACY_ACT_REQUEST_FAILED';

export const editLegacyActRequest = defaultActionCreator(EDIT_LEGACY_ACT_REQUEST, 'data', 'callback');
export const editLegacyActRequestSuccess = defaultActionCreator(EDIT_LEGACY_ACT_REQUEST_SUCCESS, 'data');
export const editLegacyActRequestFailed = defaultActionCreator(EDIT_LEGACY_ACT_REQUEST_FAILED, 'error');

export const REMOVE_LEGACY_ACT_REQUEST = 'REMOVE_LEGACY_ACT_REQUEST';
export const REMOVE_LEGACY_ACT_REQUEST_SUCCESS = 'REMOVE_LEGACY_ACT_REQUEST_SUCCESS';
export const REMOVE_LEGACY_ACT_REQUEST_FAILED = 'REMOVE_LEGACY_ACT_REQUEST_FAILED';

export const removeLegacyActRequest = defaultActionCreator(REMOVE_LEGACY_ACT_REQUEST, 'data');
export const removeLegacyActRequestSuccess = defaultActionCreator(REMOVE_LEGACY_ACT_REQUEST_SUCCESS, 'data');
export const removeLegacyActRequestFailed = defaultActionCreator(REMOVE_LEGACY_ACT_REQUEST_FAILED, 'error');

export const CHANGE_CURRENT_TASK = 'CHANGE_CURRENT_TASK';
export const changeCurrentTask = defaultActionCreator(CHANGE_CURRENT_TASK, 'data');

export const GET_ASSIGNEES_REQUEST = 'GET_ASSIGNEES_REQUEST';
export const GET_ASSIGNEES_REQUEST_SUCCESS = 'GET_ASSIGNEES_REQUEST_SUCCESS';
export const GET_ASSIGNEES_REQUEST_FAILED = 'GET_ASSIGNEES_REQUEST_FAILED';

export const getAssigneesRequest = defaultActionCreator(GET_ASSIGNEES_REQUEST, 'callback', 'data');
export const getAssigneesRequestSuccess = defaultActionCreator(GET_ASSIGNEES_REQUEST_SUCCESS, 'data');
export const getAssigneesRequestFailed = defaultActionCreator(GET_ASSIGNEES_REQUEST_FAILED, 'error');

export const ADD_ASSIGNEE_REQUEST = 'ADD_ASSIGNEE_REQUEST';
export const ADD_ASSIGNEE_REQUEST_SUCCESS = 'ADD_ASSIGNEE_REQUEST_SUCCESS';
export const ADD_ASSIGNEE_REQUEST_FAILED = 'ADD_ASSIGNEE_REQUEST_FAILED';

export const addAssigneeRequest = defaultActionCreator(ADD_ASSIGNEE_REQUEST, 'data', 'callback');
export const addAssigneeRequestSuccess = defaultActionCreator(ADD_ASSIGNEE_REQUEST_SUCCESS, 'data');
export const addAssigneeRequestFailed = defaultActionCreator(ADD_ASSIGNEE_REQUEST_FAILED, 'error');

export const RESET_COMMITMENTS_DATA = 'RESET_COMMITMENTS_DATA';
export const resetCommitmentsData = defaultActionCreator(RESET_COMMITMENTS_DATA, 'data');

export const CHANGE_CURRENT_YEAR = 'CHANGE_CURRENT_YEAR';
export const changeCurrentYear = defaultActionCreator(CHANGE_CURRENT_YEAR, 'data');

export const RESET_COMMITMENT_ERRORS = 'RESET_COMMITMENT_ERRORS';
export const resetCommitmentErrors = defaultActionCreator(RESET_COMMITMENT_ERRORS, 'data');