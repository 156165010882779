import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { changeCurrentLang } from '../../actions/commitments';
import Sectors from './Sectors/Sectors';

const Homepage = () => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const curLang = useSelector((state) => state.commitments.curLang);

  useEffect(() => {
    if (location.hash) {
      if (location.hash === '#lng=en' && curLang === 'uk') {
        localStorage.setItem('curLang', 'en');
        dispatch(changeCurrentLang('en'));
      }
      if (location.hash === '#lng=ua' && curLang === 'en') {
        localStorage.setItem('curLang', 'uk');
        dispatch(changeCurrentLang('uk'));
      }
      history.replace(`${location.pathname}${location.search}`);
    }
  }, [curLang, location.hash]); // eslint-disable-line

  return <Sectors />;
};

export default Homepage;
