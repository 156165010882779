import {
  ADD_ASSIGNEE_REQUEST_SUCCESS,
  ADD_COMMITMENT_REQUEST, ADD_COMMITMENT_REQUEST_FAILED, ADD_COMMITMENT_REQUEST_SUCCESS,
  ADD_LEGACY_ACT_REQUEST, ADD_LEGACY_ACT_REQUEST_FAILED,
  ADD_LEGACY_ACT_REQUEST_SUCCESS, ADD_TASK_REQUEST,
  ADD_TASK_REQUEST_SUCCESS,
  CHANGE_CURRENT_LANG,
  CHANGE_CURRENT_TASK, CHANGE_CURRENT_YEAR, EDIT_COMMITMENT_REQUEST, EDIT_COMMITMENT_REQUEST_FAILED,
  EDIT_COMMITMENT_REQUEST_SUCCESS, EDIT_LEGACY_ACT_REQUEST, EDIT_LEGACY_ACT_REQUEST_FAILED,
  EDIT_LEGACY_ACT_REQUEST_SUCCESS, EDIT_TASK_REQUEST,
  EDIT_TASK_REQUEST_SUCCESS,
  GET_ASSIGNEES_REQUEST_SUCCESS,
  GET_COMMITMENT_DETAIL_REQUEST,
  GET_COMMITMENT_DETAIL_REQUEST_SUCCESS,
  GET_COMMITMENT_REQUEST_SUCCESS,
  GET_COMMITMENTS_REQUEST_SUCCESS, GET_COMMITMENTS_SUMMARY_REQUEST, GET_COMMITMENTS_SUMMARY_REQUEST_SUCCESS,
  GET_SECTORS_REQUEST,
  GET_SECTORS_REQUEST_SUCCESS, REMOVE_LEGACY_ACT_REQUEST_SUCCESS,
  REMOVE_TASK_REQUEST_SUCCESS, RESET_COMMITMENT_ERRORS, RESET_COMMITMENTS_DATA,
  GET_SEARCHED_COMMITMETS_REQUEST,
  GET_SEARCHED_COMMITMETS_REQUEST_SUCCESS, GET_COMMITMENT_DETAIL_REQUEST_FAILED
} from "../actions/commitments";

const initialState = {
  sectors: [],
  sectors_year: [],
  commitments: [],
  years_summary: [],
  commitment: {},
  searchedCommitments: [],
  tasks: [],
  commitment_legacy_acts: [],
  currentTask: {},
  currentYear: {},
  loading: false,
  curLang: localStorage.getItem('curLang') || 'uk',
  assignees: [],
  upload: {
    pending: false,
    error: {}
  },
  queryRequest: {
    limit: 20,
    offset: 0
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_LANG:
      return {
        ...state,
        curLang: action.data
      };

    case GET_SECTORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SECTORS_REQUEST_SUCCESS:
      return {
        ...state,
        sectors: action.data.sectors,
        sectors_year: action.data.years,
        loading: false,
      };

    case GET_COMMITMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        commitments: [...state.commitments, ...action.data],
        queryRequest: {
          limit: state.queryRequest.limit,
          offset: state.queryRequest.offset + state.queryRequest.limit
        }
      };

    case GET_COMMITMENTS_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_COMMITMENTS_SUMMARY_REQUEST_SUCCESS:
      return {
        ...state,
        years_summary: action.data,
        currentYear: !!action.data.length ? action.data[0] : {},
        loading: false
      };

    case GET_COMMITMENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_COMMITMENT_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        commitment: action.data.commitment,
        tasks: action.data.tasks,
        commitment_legacy_acts: action.data.legacy_acts,
        loading: false
      };

    case GET_COMMITMENT_DETAIL_REQUEST_FAILED:
      return {
        ...state,
        commitment: {},
        loading: false
      };

    case GET_COMMITMENT_REQUEST_SUCCESS:
      return {
        ...state,
        commitment: action.data.commitment,
      };

    case EDIT_COMMITMENT_REQUEST_SUCCESS:
      return {
        ...state,
        commitment: action.data.commitment,
        upload: {...state.upload, pending: false},
      };

    case EDIT_COMMITMENT_REQUEST_FAILED:
    case ADD_COMMITMENT_REQUEST_FAILED:
    case EDIT_LEGACY_ACT_REQUEST_FAILED:
    case ADD_LEGACY_ACT_REQUEST_FAILED:
      return {
        ...state,
        upload: {pending: false, error: action.error.data.message},
      };

    case GET_SEARCHED_COMMITMETS_REQUEST:
      return {
        ...state,
        searchValue: action.data
      };

    case GET_SEARCHED_COMMITMETS_REQUEST_SUCCESS:
      return {
        ...state,
        searchedCommitments: action.data
      };

    case ADD_TASK_REQUEST:
    case EDIT_TASK_REQUEST:
    case ADD_LEGACY_ACT_REQUEST:
    case EDIT_LEGACY_ACT_REQUEST:
    case ADD_COMMITMENT_REQUEST:
    case EDIT_COMMITMENT_REQUEST:
      return {
        ...state,
        upload: {...state.upload, pending: true}
      };

    case ADD_COMMITMENT_REQUEST_SUCCESS:
      return {
        ...state,
        upload: {...state.upload, pending: false}
      };

    case ADD_TASK_REQUEST_SUCCESS:
      return {
        ...state,
        tasks: action.data[0],
        commitment: {...state.commitment, execution: action.data[1].execution},
        currentTask: action.data[0].length === 1 ? action.data[0][0] : state.currentTask,
        upload: {...state.upload, pending: false}
      };

    case EDIT_TASK_REQUEST_SUCCESS:
      return {
        ...state,
        tasks: action.data[0],
        commitment: {...state.commitment, execution: action.data[1].execution},
        currentTask: action.data[2],
        upload: {...state.upload, pending: false}
      };

    case REMOVE_TASK_REQUEST_SUCCESS:
      return {
        ...state,
        commitment: {...state.commitment, execution: action.data.commitment.execution},
        tasks: action.data.tasks,
        currentTask: action.data.currentTask
      };

    case CHANGE_CURRENT_TASK:
      return {
        ...state,
        currentTask: action.data
      };

    case ADD_LEGACY_ACT_REQUEST_SUCCESS:
    case EDIT_LEGACY_ACT_REQUEST_SUCCESS:
    case REMOVE_LEGACY_ACT_REQUEST_SUCCESS:
      return {
        ...state,
        currentTask: action.data.currentTask,
        tasks: action.data.tasks,
        upload: {...state.upload, pending: false}
      };

    case GET_ASSIGNEES_REQUEST_SUCCESS:
      return {
        ...state,
        assignees: action.data
      };

    case RESET_COMMITMENTS_DATA:
      return {
        ...state,
        commitments: [],
        queryRequest: {
          limit: state.queryRequest.limit,
          offset: 0
        }
      };

    case CHANGE_CURRENT_YEAR:
      return {
        ...state,
        currentYear: action.data
      };

    case ADD_ASSIGNEE_REQUEST_SUCCESS:
      return {
        ...state,
        assignees: [...state.assignees, action.data]
      };

    case RESET_COMMITMENT_ERRORS:
      return {
        ...state,
        upload: {error: {}, pending: false}
      };

    default:
      return state;
  }
}