import axios from 'axios';
import {doReafreshTokenApi} from "./auth";
import jwt_decode from "jwt-decode";

class Service {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error)
  };

  async withCheckToken(callback){
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));
    if(token){
      const decoded = jwt_decode(token.access_token);
      if(decoded.exp - 150 < Date.now() / 1000){
        const response = await doReafreshTokenApi();
        localStorage.setItem('token', JSON.stringify({
          access_token: response.data.access_token,
          refresh_token: token.refresh_token,
        }));
      }
    }
    return callback();
  }

  get(path, params, callback) {
    return this.service.get(path, {
      params,
      headers: {
        // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).then(
      (response) => callback ? callback(response.status, response.data) : response
    );
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).then(response => callback ? callback(response.status, response.data) : response);
  }

  put(put, payload, callback) {
    return this.withCheckToken(
      () => {
        return this.service.request({
          method: 'PUT',
          url: put,
          responseType: 'json',
          data: payload,
          headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
            'Access-Control-Allow-Origin': '*'
          }
        }).then(response => callback ? callback(response.status, response.data) : response)
      }
    );
  }

  post(path, payload, callback) {
    return this.withCheckToken(
      () => {
        return this.service.request({
          method: 'POST',
          url: path,
          responseType: 'json',
          data: payload,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token : ''}`,
            'Access-Control-Allow-Origin': '*'
          }
        }).then((response) => callback ? callback(response.status, response.data) : response)
      }
    );
  }

  delete(path, payload, callback) {
    return this.withCheckToken(
      () => {
        return this.service.request({
          method: 'DELETE',
          url: path,
          responseType: 'json',
          data: payload,
          headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
            'Access-Control-Allow-Origin': '*'
          }
        }).then((response) => callback ? callback(response.status, response.data) : response)
      }
    );
  }

}

export default new Service();