import service from './service';
import {API_URL} from "../helpers/constants";

export const getSectorsApi = () => {
  return service.get(
    `${API_URL}/sectors`,
  )
};

export const getCommitmentsApi = (data) => {
  return service.get(
    `${API_URL}/sectors/${data.path}`, data.query
  )
};

export const getCommitmentsSummaryApi = (data) => {
  return service.get(
    `${API_URL}/sectors_summary/${data}`
  )
};

export const getCommitmentApi = (data) => {
  return service.get(
    `${API_URL}/commitment/${data.path}/${data.id}`,
  )
};

export const getCommitmentDetailApi = (data) => {
  return service.get(
    `${API_URL}/commitments/${data}`,
  )
};

export const getSearchedCommitmentsApi = (data) => {
  return service.get(
    `${API_URL}/commitments?search=${data}`
  )
};

export const addCommitmentApi = (data) => {
  return service.post(
    `${API_URL}/sectors/${data.path}`, data.body
  )
};

export const editCommitmentApi = (data) => {
  return service.put(
    `${API_URL}/commitments/${data.path}`, data.body
  )
};

export const removeCommitmentApi = (data) => {
  return service.delete(
    `${API_URL}/commitments/${data.path}/${data.id}`
  )
};

export const addTaskApi = (data) => {
  return service.post(
    `${API_URL}/tasks/${data.path}`, data.body
  )
};

export const editTaskApi = (data) => {
  return service.put(
    `${API_URL}/task/${data.path}/${data.id}`, data.body
  )
};

export const removeTaskApi = (data) => {
  return service.delete(
    `${API_URL}/task/${data.path}/${data.id}`
  )
};

export const addLegacyActApi = (data) => {
  return service.post(
    `${API_URL}/legacy-acts/${data.path}/${data.taskId}`, data.body
  )
};

export const editLegacyActApi = (data) => {
  return service.put(
    `${API_URL}/legacy-act/${data.path}/${data.taskId}/${data.legacyActId}`, data.body
  )
};

export const removeLegacyActApi = (data) => {
  return service.delete(
    `${API_URL}/legacy-act/${data.path}/${data.taskId}/${data.legacyActId}`
  )
};

export const getAssigneesApi = () => {
  return service.get(
    `${API_URL}/assignees`,
  )
};

export const addAssigneeApi = (data) => {
  return service.post(
    `${API_URL}/assignees`, data
  )
};
