import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as UkraineIcon } from '../../assets/images/ukraine.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { getTranslations } from '../../helpers/translations';
import './NavigatorHeader.scss';

const NavigatorHeader = () => {
  const curLang = useSelector((state) => state.commitments.curLang);
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (history.location.pathname.includes('/search')) {
      const URLSearch = history.location.pathname.split('=').pop();
      setSearchValue(URLSearch);
    }
  }, [history.location.pathname]);

  const submitSearch = (e) => {
    e.preventDefault();
    history.push(`/search=${searchValue}`);
  };

  return (
    <div className='navigatorHeader'>
      <div className='navigatorHeader__left'>
        <Link to='/'>
          <UkraineIcon />
        </Link>
        <div className='navigatorHeader__text'>
          <h1>{getTranslations('navigatorHeader_title', curLang)}</h1>
          <p className='text'>{getTranslations('navigatorHeader_text', curLang)}</p>
        </div>
      </div>
      <div className='navigatorHeader__right'>
        <div className='navigatorHeader__search search border'>
          <div className='search__wrap' onClick={submitSearch}>
            <SearchIcon />
          </div>
          <form onSubmit={submitSearch}>
            <input
              type='text'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={getTranslations('search_placeholder', curLang)}
              required
            />
          </form>
        </div>
      </div>
    </div>
  );
};

NavigatorHeader.propTypes = {
  curLang: PropTypes.string,
};

export default NavigatorHeader;
