import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import './assets/styles/globals.scss';
import Header from './components/Header/Header';
import NavigatorInfo from './components/NavigatorInfo/NavigatorInfo';
import NavigatorHeader from './components/NavigatorHeader/NavigatorHeader';
import Commitments from './pages/Commitments/Commitments';
import Homepage from './pages/Homepage/Homepage';
import Search from './pages/Search/Search';
import Login from './pages/Login/Login';
import Commitment from './pages/Commitment/Commitment';
import Footer from './components/Footer/Footer';

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className='App border'>
        <div className='container'>
          <Header />
          <NavigatorHeader />

          <Switch>
            <Route path='/' component={Homepage} exact />
            <Route path='/admin_access' component={Login} />
            <Route path='/sectors/:id' component={Commitments} />
            <Route path='/commitment/:id' component={Commitment} />
            <Route path='/search=:id' component={Search} />
            <Redirect to='/' />
          </Switch>
          <NavigatorInfo />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;
