import React, { useEffect, useState } from 'react';

import './Header.scss';
import HeaderContent from './HeaderContent';

const Header = () => {
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    if (prevScrollPos > 20 && !isScrolled) {
      setScrolled(true);
    }

    const onScroll = () => {
      let currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 20 && !isScrolled) {
        setScrolled(true);
      }
      if (currentScrollPos <= 20) {
        setScrolled(false);
      }
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isScrolled]);

  return (
    <header className={`header ${isScrolled ? 'fixed' : ''}`}>
      <HeaderContent />
    </header>
  );
};

export default Header;
