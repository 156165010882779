import React, {forwardRef} from 'react';
import {getTranslations} from "../../helpers/translations";
import './Execution.scss'

const Execution = ({curLang, percentage}) => {
  return(
    <div className={`execution execution-${isNaN(percentage) ? 'n_a' : Math.ceil(percentage/20)}`}>
      <p>{getTranslations('execution', curLang)}</p>
      <h4>{isNaN(percentage) ? percentage : Math.round(percentage * 100) / 100 + '%'}</h4>
    </div>
  )
};

export const ExecutionWithCount = forwardRef((props, ref) => {
  const {item, ...rest} = props;
  return(
    <div
      {...rest}
      className={`execution execution-small execution-${isNaN(item.execution) ? 'n_a' : Math.ceil(item.execution/20)}`}
      ref={ref}
    >
      <p>{isNaN(item.execution) ? item.execution : Math.round(item.execution * 100) / 100 + '%'}</p>
      <span>{item.count}</span>
    </div>
  )
});


export default Execution;