import React from 'react'
import KASIcon from '../../assets/images/kas.png';
import UCEPIconUK from '../../assets/images/ucep-uk.png';
import UCEPIconEN from '../../assets/images/ucep-en.png';
import {ReactComponent as LasoftIcon} from '../../assets/images/lasoft.svg';
import {ReactComponent as KingdomIcon} from '../../assets/images/kingdom.svg';
import './NavigatorInfo.scss'
import {getTranslations} from "../../helpers/translations";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const NavigatorInfo = () => {
  const curLang = useSelector(state => state.commitments.curLang);
  return (
    <div className='navigatorInfo'>
      <div className="navigatorInfo__partners">
        <a href='http://ucep.org.ua/' target='_blank' rel='noopener noreferrer'>
          <img src={curLang === 'uk' ? UCEPIconUK : UCEPIconEN} alt=""/>
        </a>
        <a href='https://www.kas.de/de/home' target='_blank' rel='noopener noreferrer'>
          <img src={KASIcon} alt=""/>
        </a>
        <a href='https://lasoft.org/' target='_blank' rel='noopener noreferrer'>
          <LasoftIcon/>
        </a>
        <a href='https://www.government.nl/' target='_blank' rel='noopener noreferrer'>
          <KingdomIcon/>
        </a>
      </div>
      <div className="navigatorInfo__text">
        <p>
          <b>{getTranslations('navigatorInfo_warning', curLang)} </b>
          {getTranslations('navigatorInfo_text_1', curLang)}
        </p>
        <p>
          {getTranslations('navigatorInfo_text_2', curLang)}
        </p>
      </div>
    </div>
  )
};

NavigatorInfo.propTypes = {
  curLang: PropTypes.string,
};

export default NavigatorInfo;