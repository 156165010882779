import { all, select, call, put, takeEvery } from 'redux-saga/effects'

import * as commitmentsActions from '../actions/commitments'
import {
  getSectorsApi,
  getCommitmentsApi,
  getCommitmentApi,
  addCommitmentApi,
  removeCommitmentApi,
  editCommitmentApi,
  addTaskApi,
  removeTaskApi,
  editTaskApi,
  getCommitmentDetailApi,
  addLegacyActApi,
  editLegacyActApi,
  getAssigneesApi,
  removeLegacyActApi,
  getCommitmentsSummaryApi,
  addAssigneeApi,
  getSearchedCommitmentsApi
} from "../requests/commitments";

export function* getSectors() {
  try {
    const response = yield call(getSectorsApi);
    yield put({type: commitmentsActions.GET_SECTORS_REQUEST_SUCCESS, data: response.data});
  } catch (e) {
    yield put({ type: commitmentsActions.GET_SECTORS_REQUEST_FAILED, error: e.response });
  }
}

export function* getCommitments(action) {
  try {
    const assignees = yield select(state => state.commitments.assignees);
    if (!assignees.length) yield put({type: commitmentsActions.GET_ASSIGNEES_REQUEST});
    const response = yield call(getCommitmentsApi, action.data);
    yield put({type: commitmentsActions.GET_COMMITMENTS_REQUEST_SUCCESS, data: response.data});
  } catch (e) {
    yield put({ type: commitmentsActions.GET_COMMITMENTS_REQUEST_FAILED, error: e.response });
  }
}

export function* getCommitmentsSummary(action) {
  try {
    const response = yield call(getCommitmentsSummaryApi, action.data);
    yield put({type: commitmentsActions.GET_COMMITMENTS_SUMMARY_REQUEST_SUCCESS, data: response.data});
  } catch (e) {
    yield put({ type: commitmentsActions.GET_COMMITMENTS_SUMMARY_REQUEST_FAILED, error: e.response });
  }
}

export function* getCommitmentDetail(action) {
  try {
    const assignees = yield select(state => state.commitments.assignees);
    if (!assignees.length) yield put({type: commitmentsActions.GET_ASSIGNEES_REQUEST});
    const response = yield call(getCommitmentDetailApi, action.data);
    if(response.data.tasks){
      response.data.legacy_acts = [...response.data.tasks.reduce((prev, item) => {
        if(item.legacy_acts) return [...prev, ...item.legacy_acts];
        return prev;
      }, [])];
    }
    yield put({type: commitmentsActions.GET_COMMITMENT_DETAIL_REQUEST_SUCCESS, data: response.data});
  } catch (e) {
    yield put({ type: commitmentsActions.GET_COMMITMENT_DETAIL_REQUEST_FAILED, error: e.response });
  }
}

export function* getCommitment(action) {
  try {
    const response = yield call(getCommitmentApi, action.data);
    yield put({type: commitmentsActions.GET_COMMITMENT_REQUEST_SUCCESS, data: response.data});
    action.callback(true);
  } catch (e) {
    yield put({ type: commitmentsActions.GET_COMMITMENT_REQUEST_FAILED, error: e.response });
  }
}

export function* addCommitment(action) {
  try {
    const response = yield call(addCommitmentApi, action.data);
    yield put({type: commitmentsActions.RESET_COMMITMENTS_DATA});
    action.callback(false);
    yield put({type: commitmentsActions.ADD_COMMITMENT_REQUEST_SUCCESS, data: response.data});
    yield put({type: commitmentsActions.RESET_COMMITMENT_ERRORS});

    yield put({type: commitmentsActions.GET_COMMITMENTS_SUMMARY_REQUEST, data: action.data.path});
    const queryRequest = yield select(state => state.commitments.queryRequest);
    yield put({
      type: commitmentsActions.GET_COMMITMENTS_REQUEST,
      data: {
        path: action.data.path,
        query: queryRequest
      }
    });
  } catch (e) {
    yield put({ type: commitmentsActions.ADD_COMMITMENT_REQUEST_FAILED, error: e.response });
  }
}

export function* editCommitment(action) {
  try {
    const response = yield call(editCommitmentApi, action.data);
    yield put({type: commitmentsActions.RESET_COMMITMENTS_DATA});

    yield put({type: commitmentsActions.EDIT_COMMITMENT_REQUEST_SUCCESS, data: response.data});
    yield put({type: commitmentsActions.RESET_COMMITMENT_ERRORS});

    const searchedValue = yield select(state => state.commitments.searchValue);
    if(searchedValue){
      yield put({type: commitmentsActions.GET_SEARCHED_COMMITMETS_REQUEST, data: searchedValue})
    }

    if(action.data.realPath && !window.location.pathname.includes('search=')){
      yield put({type: commitmentsActions.GET_COMMITMENTS_SUMMARY_REQUEST, data: action.data.realPath});
      const queryRequest = yield select(state => state.commitments.queryRequest);
      yield put({
        type: commitmentsActions.GET_COMMITMENTS_REQUEST,
        data: {
          path: action.data.realPath,
          query: queryRequest
        }
      });
    }else {
      action.callback(false);
    }
  } catch (e) {
    yield put({ type: commitmentsActions.EDIT_COMMITMENT_REQUEST_FAILED, error: e.response });
  }
}

export function* removeCommitment(action) {
  yield put({type: commitmentsActions.RESET_COMMITMENTS_DATA});
  try {
    const response = yield call(removeCommitmentApi, action.data);

    yield put({type: commitmentsActions.REMOVE_COMMITMENT_REQUEST_SUCCESS, data: response.data});
    yield put({type: commitmentsActions.GET_COMMITMENTS_SUMMARY_REQUEST, data: action.data.path});

    const searchedValue = yield select(state => state.commitments.searchValue);
    if(searchedValue){
      yield put({type: commitmentsActions.GET_SEARCHED_COMMITMETS_REQUEST, data: searchedValue})
    }

    const queryRequest = yield select(state => state.commitments.queryRequest);
    yield put({
      type: commitmentsActions.GET_COMMITMENTS_REQUEST,
      data: {
        path: action.data.path,
        query: queryRequest
      }
    });
  } catch (e) {
    yield put({ type: commitmentsActions.REMOVE_COMMITMENT_REQUEST_FAILED, error: e.response });
  }
}

export function* addTask(action) {
  try {
    const response = yield call(addTaskApi, action.data);
    action.callback(false);
    yield put({type: commitmentsActions.ADD_TASK_REQUEST_SUCCESS, data: response.data});
  } catch (e) {
    yield put({ type: commitmentsActions.ADD_TASK_REQUEST_FAILED, error: e.response });
  }
}

export function* editTask(action) {
  try {
    const response = yield call(editTaskApi, action.data);
    action.callback(false);
    let currentTaskState = yield select(state => state.commitments.currentTask);
    const currentTask = currentTaskState._id === action.data.id
      ? response.data[0].find(item => item._id === action.data.id)
      : currentTaskState;
    yield put({type: commitmentsActions.EDIT_TASK_REQUEST_SUCCESS, data: [...response.data, currentTask]});
  } catch (e) {
    yield put({ type: commitmentsActions.EDIT_TASK_REQUEST_FAILED, error: e.response });
  }
}

export function* removeTask(action) {
  try {
    const response = yield call(removeTaskApi, action.data);

    let tasksState = yield select(state => state.commitments.tasks);
    let currentTaskState = yield select(state => state.commitments.currentTask);
    const tasks = tasksState.filter(item => item._id !== action.data.id);
    const currentTask = currentTaskState._id === action.data.id ? tasks[0] : currentTaskState;

    yield put({
      type: commitmentsActions.REMOVE_TASK_REQUEST_SUCCESS,
      data: {...response.data, ...action.data, tasks, currentTask}});
  } catch (e) {
    yield put({ type: commitmentsActions.REMOVE_TASK_REQUEST_FAILED, error: e.response });
  }
}

export function* addLegacyAct(action) {
  try {
    const response = yield call(addLegacyActApi, action.data);

    action.callback(false);

    let tasksState = yield select(state => state.commitments.tasks);
    let currentTaskState = yield select(state => state.commitments.currentTask);
    const currentTask = {
      ...currentTaskState,
      legacy_acts: currentTaskState.legacy_acts
        ? [...currentTaskState.legacy_acts, response.data]
        : [response.data]
    };

    const tasks = tasksState.map(item => {
      if(currentTask._id === item._id){
        return currentTask;
      }
      return item;
    });

    yield put({type: commitmentsActions.ADD_LEGACY_ACT_REQUEST_SUCCESS, data: {currentTask, tasks}});
  } catch (e) {
    yield put({ type: commitmentsActions.ADD_LEGACY_ACT_REQUEST_FAILED, error: e.response });
  }
}

export function* editLegacyAct(action) {
  try {
    const response = yield call(editLegacyActApi, action.data);

    action.callback(false);

    let tasksState = yield select(state => state.commitments.tasks);
    let currentTaskState = yield select(state => state.commitments.currentTask);
    const currentTask = {
      ...currentTaskState,
      legacy_acts: currentTaskState.legacy_acts.map(item => {
        if(response.data._id === item._id){
          return response.data;
        }
        return item;
      }
    )};

    const tasks = tasksState.map(item => {
      if(currentTask._id === item._id){
        return currentTask;
      }
      return item;
    });

    yield put({type: commitmentsActions.EDIT_LEGACY_ACT_REQUEST_SUCCESS, data: {currentTask, tasks}});
  } catch (e) {
    yield put({ type: commitmentsActions.EDIT_LEGACY_ACT_REQUEST_FAILED, error: e.response });
  }
}

export function* removeLegacyAct(action) {
  try {
    yield call(removeLegacyActApi, action.data);
    let tasksState = yield select(state => state.commitments.tasks);
    let currentTaskState = yield select(state => state.commitments.currentTask);
    const currentTask = {
      ...currentTaskState,
      legacy_acts: currentTaskState.legacy_acts.filter(item => action.data.legacyActId !== item._id)
    };

    const tasks = tasksState.map(item => {
      if(currentTask._id === item._id){
        return currentTask;
      }
      return item;
    });

    yield put({type: commitmentsActions.REMOVE_LEGACY_ACT_REQUEST_SUCCESS, data: {currentTask, tasks}});
  } catch (e) {
    yield put({ type: commitmentsActions.REMOVE_LEGACY_ACT_REQUEST_FAILED, error: e.response });
  }
}

export function* getAssignees(action) {
  try {
    const response = yield call(getAssigneesApi);
    yield put({type: commitmentsActions.GET_ASSIGNEES_REQUEST_SUCCESS, data: response.data});
    if(action.callback) action.callback(true);
  } catch (e) {
    yield put({ type: commitmentsActions.GET_ASSIGNEES_REQUEST_FAILED, error: e.response });
  }
}

export function* addAssignee(action) {
  try {
    const response = yield call(addAssigneeApi, action.data);
    yield put({type: commitmentsActions.ADD_ASSIGNEE_REQUEST_SUCCESS, data: response.data});
    action.callback(response.data);
  } catch (e) {
    yield put({ type: commitmentsActions.ADD_ASSIGNEE_REQUEST_FAILED, error: e.response });
  }
}

export function* getSearchedCommitments(action) {
  try {
    const response = yield call(getSearchedCommitmentsApi, action.data);
    yield put({ type: commitmentsActions.GET_SEARCHED_COMMITMETS_REQUEST_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: commitmentsActions.GET_SEARCHED_COMMITMETS_REQUEST_FAILD, error: e.response })
  }
}

export default all([
  takeEvery(commitmentsActions.GET_SECTORS_REQUEST, getSectors),
  takeEvery(commitmentsActions.GET_COMMITMENTS_REQUEST, getCommitments),
  takeEvery(commitmentsActions.GET_COMMITMENTS_SUMMARY_REQUEST, getCommitmentsSummary),
  takeEvery(commitmentsActions.GET_COMMITMENT_DETAIL_REQUEST, getCommitmentDetail),
  takeEvery(commitmentsActions.GET_COMMITMENT_REQUEST, getCommitment),
  takeEvery(commitmentsActions.ADD_COMMITMENT_REQUEST, addCommitment),
  takeEvery(commitmentsActions.EDIT_COMMITMENT_REQUEST, editCommitment),
  takeEvery(commitmentsActions.REMOVE_COMMITMENT_REQUEST, removeCommitment),
  takeEvery(commitmentsActions.ADD_TASK_REQUEST, addTask),
  takeEvery(commitmentsActions.EDIT_TASK_REQUEST, editTask),
  takeEvery(commitmentsActions.REMOVE_TASK_REQUEST, removeTask),
  takeEvery(commitmentsActions.ADD_LEGACY_ACT_REQUEST, addLegacyAct),
  takeEvery(commitmentsActions.EDIT_LEGACY_ACT_REQUEST, editLegacyAct),
  takeEvery(commitmentsActions.REMOVE_LEGACY_ACT_REQUEST, removeLegacyAct),
  takeEvery(commitmentsActions.GET_ASSIGNEES_REQUEST, getAssignees),
  takeEvery(commitmentsActions.ADD_ASSIGNEE_REQUEST, addAssignee),
  takeEvery(commitmentsActions.GET_SEARCHED_COMMITMETS_REQUEST, getSearchedCommitments),
])