const MAIN_UCEP_URL = `https://ucep.org.ua`;

const translations = {
  navigatorHeader_title: {
    uk: `Навігатор Угоди`,
    en: `AA Navigator`,
  },
  navigatorHeader_text: {
    uk: `Скільки зобов'язань має виконати Україна відповідно до Угоди про асоціацію? І коли?`,
    en: `How many obligations has Ukraine to fulfil according to the Association Agreement? And in what term?`,
  },
  search_placeholder: {
    uk: `Пошук за назвою або номером зобов'язання`,
    en: `Search by name or commitment number`,
  },
  navigatorInfo_warning: {
    uk: `Застереження:`,
    en: `Disclaimer:`,
  },
  navigatorInfo_text_1: {
    uk: `Навігатор Угоди - це онлайн-інструмент, який було створено для відображення кількісних та якісних результатів незалежного експертного моніторингу виконання Угоди про асоціацію з ЄС з боку України. Наведені кількісні значення прогресу базуються на унікальній методології оцінки, розробленої ГО “Український центр європейської політики” (ГО «УЦЄП»), і тому не можуть бути прямо або опосередковано порівняні з аналогічними оцінками урядових, міжнародних інституцій та організацій громадянського суспільства. Ні ГО «УЦЄП», ні Фонд Конрада Аденауера не несе відповідальності за будь-які рішення, які можуть бути прийняті внаслідок використання інформації та даних, наведених у цьому інструменті.`,
    en: `AA Navigator is an online tool created to display the quantitative and qualitative outcomes of independent expert monitoring regarding Ukraine's implementation of the Association Agreement with the EU. The tool’s quantitative scores are based on a unique assessment methodology developed by the NGO "Ukrainian Center for European Policy" (UCEP). Thus, results cannot be compared directly or indirectly with similar monitoring conducted by governmental or international institutions and civil society organizations. Furthermore, neither the UCEP nor the Konrad Adenauer Foundation are responsible for any decisions taken after using the data provided by the tool.`,
  },
  navigatorInfo_text_2: {
    uk: `Оскільки оновлення інформації у Навігаторі Угоди здійснюється на періодичній основі, наведені нормативно-правові акти ЄС та українського законодавства, набір зобов'язань та завдань для відповідальних урядових виконавців, а також експертні оцінки можуть не завжди відповідати актуальній ситуації. Оцінка експертами ГО «УЦЄП» стану виконання зобов’язань України за Угодою про асоціацію з ЄС базується на статус-кво станом на кінець 2022 року.`,
    en: `Data used in the AA Navigator is being updated on a periodic basis. Yet, at times EU regulations, Ukrainian legislation, the set of commitments and tasks for the responsible government agencies, as well as expert assessments might not be provided in its current versions. UCEP experts' assessment of the state of implementation of Ukraine's commitments under the Association Agreement with the EU is based on the status quo at the end of 2022.`,
  },
  search_results: {
    uk: `Результати пошуку за запитом`,
    en: `Search results on request`,
  },
  login_title: {
    uk: `Авторизація`,
    en: `Authorization`,
  },
  login_btn_title: {
    uk: `Увійти`,
    en: `Sign in`,
  },
  login_username_placeholder: {
    uk: `Ім'я користувача`,
    en: `Username`,
  },
  login_password_placeholder: {
    uk: `Пароль`,
    en: `Password`,
  },
  commitment_title: {
    uk: `Зобов'язання`,
    en: `Commitment`,
  },
  commitments_title: {
    uk: `Зобов'язання`,
    en: `Commitments`,
  },
  commitment_title_lower_case: {
    uk: `зобов'язання`,
    en: `commitment`,
  },
  assignee: {
    uk: `Виконавець`,
    en: `Assignee`,
  },
  assignees: {
    uk: `Виконавці`,
    en: `Assignees`,
  },
  deadline: {
    uk: 'Термін виконання',
    en: 'Deadline',
  },
  link: {
    uk: 'Посилання',
    en: 'Link',
  },
  implementation_not_started: {
    uk: 'Виконання не розпочато',
    en: 'Implementation not started',
  },
  early_implementation: {
    uk: 'Рання стадія виконання',
    en: 'Early implementation',
  },
  advanced_implementation: {
    uk: 'Просунута стадія виконання',
    en: 'Advanced implementation',
  },
  perfect_execution: {
    uk: 'Досконале виконання',
    en: 'Perfect execution',
  },
  critical_discrepancy: {
    uk: 'Критична невідповідність',
    en: 'Critical discrepancy',
  },
  legal_approximation: {
    uk: 'Наближення законодавства',
    en: 'Legal approximation',
  },
  implementation: {
    uk: 'Практична імплементація',
    en: 'Implementation',
  },
  tasks_title: {
    uk: `Завдання`,
    en: `Tasks`,
  },
  task_title: {
    uk: `завдання`,
    en: `task`,
  },
  hide_actions: {
    uk: `Приховати заходи`,
    en: `Hide actions`,
  },
  show_actions: {
    uk: `Показати заходи`,
    en: `Show actions`,
  },
  add_commitment: {
    uk: `Добавити зобов'язання`,
    en: `Add commitment`,
  },
  add_task: {
    uk: `Добавити завдання`,
    en: `Add task`,
  },
  save: {
    uk: `Зберегти`,
    en: `Save`,
  },
  save_assignee: {
    uk: `Зберегти виконавця`,
    en: `Save assignee`,
  },
  cancel: {
    uk: `Відмінити`,
    en: `Cancel`,
  },
  delete: {
    uk: `Видалити`,
    en: `Delete`,
  },
  execution: {
    uk: `Оцінка виконання`,
    en: `Execution`,
  },
  new_commitment: {
    uk: `Нове зобов’язання`,
    en: `New commitment`,
  },
  edit_commitment: {
    uk: `Редагувати зобов’язання`,
    en: `Edit commitment`,
  },
  new_task: {
    uk: `Нове завдання`,
    en: `New task`,
  },
  edit_task: {
    uk: `Редагувати завдання`,
    en: `Edit task`,
  },
  add_action: {
    uk: `Додати Захід`,
    en: `Add Action`,
  },
  add_eu_acts: {
    uk: `Додати Акти ЄС`,
    en: `Add EU Acts`,
  },
  add_act: {
    uk: `Додати акт`,
    en: `Add act`,
  },
  edit_act: {
    uk: `Редагувати акт`,
    en: `Edit act`,
  },
  new_act: {
    uk: `Новий акт`,
    en: `New act`,
  },
  in_force: {
    uk: `Чинний`,
    en: `In force`,
  },
  draft: {
    uk: `Проект`,
    en: `Draft`,
  },
  in_force_plural: {
    uk: `Чинні`,
    en: `In force`,
  },
  draft_plural: {
    uk: `Проекти`,
    en: `Draft`,
  },
  legacy_acts: {
    uk: `Нормативно-правові акти`,
    en: `Legacy acts`,
  },
  legacy_act_title: {
    uk: `нормативно-правовий акт`,
    en: `legacy act`,
  },
  all: {
    uk: `Всі роки`,
    en: `All years`,
  },
  all_years: {
    uk: `за всі роки`,
    en: `for all years`,
  },
  not_defined_years: {
    uk: `за без дати`,
    en: `for no date`,
  },
  for: {
    uk: `на`,
    en: `for`,
  },
  year: {
    uk: `рік`,
    en: `year`,
  },
  year_capital: {
    uk: `Рік`,
    en: `Year`,
  },
  logout: {
    uk: `Вийти`,
    en: `Logout`,
  },
  menu: {
    uk: 'Меню',
    en: 'Menu',
  },
  home_url: {
    uk: `https://ucep.org.ua`,
    en: `https://ucep.org.ua/en`,
  },
  name_of_link_about_us: { uk: `Про нас`, en: `About us` },
  name_of_link_our_team: { uk: `Команда`, en: `Our team` },
  name_of_link_our_research: { uk: `Наші дослідження`, en: `Our research` },
  name_of_link_news: { uk: `Новини`, en: `News` },
  name_of_link_our_projects: { uk: `Наші проєкти`, en: `Our projects` },
  name_of_link_navigator: { uk: `Навігатор Угоди`, en: `AA Navigator` },
  name_of_link_contacts: { uk: `Контакти`, en: `Contacts` },
  link_about_us: { uk: `${MAIN_UCEP_URL}/pro-nas`, en: `${MAIN_UCEP_URL}/en/pro-nas` },
  link_our_team: { uk: `${MAIN_UCEP_URL}/team`, en: `${MAIN_UCEP_URL}/en/team` },
  link_our_research: {
    uk: `${MAIN_UCEP_URL}/category/doslidzhennya`,
    en: `${MAIN_UCEP_URL}/en/category/doslidzhennya`,
  },
  link_news: { uk: `${MAIN_UCEP_URL}/category/novyny`, en: `${MAIN_UCEP_URL}/en/category/novyny` },
  link_our_projects: { uk: `${MAIN_UCEP_URL}/category/projects`, en: `${MAIN_UCEP_URL}/en/category/projects` },
  link_navigator: { uk: `/`, en: `/` },
  link_contacts: { uk: `${MAIN_UCEP_URL}/contacts`, en: `${MAIN_UCEP_URL}/en/contacts` },
  footer_text: {
    uk: 'Український центр європейської політики',
    en: 'Ukrainian Center for European Policy',
  },
  home: {
    uk: `Головна`,
    en: `Home`,
  },
  eu_acts: {
    uk: 'Акти ЄС',
    en: 'EU Acts',
  },
  chapter: {
    uk: `Розділ`,
    en: `Chapter`,
  },
  sectors: {
    uk: `Сектори`,
    en: `Sectors`,
  },
  execution_and_commitments: {
    uk: `Оцінка прогресу та кількість зобов'язань`,
    en: `Assessment of progress and number of commitments`,
  },
  found_commitments: {
    uk: `Знайдено зобов'язань`,
    en: 'Found commitments',
  },
  not_defined: {
    uk: `Без дати`,
    en: `No date`,
  },
  expert_comment: {
    uk: `Коментар`,
    en: `Comment`,
  },
  empty_commitment: {
    uk: `Зобов’язання не існує`,
    en: `Commitment doesn't exist`,
  },
  required: {
    uk: `Обов’язкове поле`,
    en: `This field is required`,
  },
  status: {
    uk: 'Статус',
    en: 'Status',
  },
  task_status: {
    uk: 'Статус виконання',
    en: 'Task status',
  },
  task_type: {
    uk: 'Тип завдання',
    en: 'Task type',
  },
  ok: {
    uk: 'Вибрати',
    en: 'Ok',
  },
  invalid_link: {
    uk: 'Формат посилання: https://example.com',
    en: 'Invalid link. Example link: https://example.com',
  },
  invalid_date: {
    uk: 'Недійсний формат дати',
    en: 'Invalid date format',
  },
  maxDateMessage: {
    uk: 'Максимум 2100 рік, будь ласка',
    en: 'Maximum 2100, please',
  },
  minDateMessage: {
    uk: 'Мінімум 1900 рік, будь ласка',
    en: 'Minimum 1900, please',
  },

  // sectors slug
  overall_summary: {
    uk: 'Всі сектори',
    en: 'All sectors',
  },
  'dialogue-and-cooperation-on-domestic-reforms': {
    uk: 'Діалог і співробітництво з питань внутрішніх реформ',
    en: 'Dialogue and cooperation on domestic reforms',
  },
  'decentralization-and-civil-service': {
    uk: 'Децентралізація та державна служба',
    en: 'Decentralization and civil service',
  },
  'electoral-and-parliamentary-reform': {
    uk: 'Виборча та парламентська реформа',
    en: 'Electoral and parliamentary reform',
  },
  'foreign-and-security-policy': {
    uk: 'Зовнішня та безпекова політика',
    en: 'Foreign and security policy',
  },
  'national-security-and-defence': {
    uk: 'Зовнішня, безпекова і оборонна політика',
    en: 'Foreign, security and defence policy',
  },
  'regional-stability': {
    uk: 'Регіональна стабільність',
    en: 'Regional stability',
  },
  'conflict-prevention': {
    uk: 'Попередження конфліктів, антикризове управління та військово-технічне співробітництво',
    en: 'Conflict prevention, crisis management and military-technological cooperation',
  },
  'non-proliferation-mass-destruction': {
    uk: 'Нерозповсюдження зброї масового знищення',
    en: 'Non-proliferation of weapons of mass destruction',
  },
  'disarmament-arms-control-arms': {
    uk: 'Роззброєння, контроль над озброєннями, експортний контроль та боротьба з незаконною торгівлею зброєю',
    en: 'Disarmament, arms control, arms export control and the fight against illicit trafficking of arms',
  },
  'combating-terrorism': {
    uk: 'Боротьба з тероризмом',
    en: 'Combating terrorism',
  },
  'the-rule-of-law-and-respect-for-human-rights-and-fundamental-freedoms': {
    uk: 'Верховенство права та повага до прав людини і основоположних свобод',
    en: 'The rule of law and respect for human rights and fundamental freedoms',
  },
  'reform-of-law-enforcement-and-the-judiciary': {
    uk: 'Реформа органів правопорядку та судової системи',
    en: 'Reform of law enforcement and the judiciary',
  },
  'combating-discrimination-and-protection-of-human-rights': {
    uk: 'Протидія дискримінації та захист прав людини',
    en: 'Combating discrimination and protection of human rights',
  },
  'protection-of-personal-data': {
    uk: 'Захист персональних даних',
    en: 'Protection of personal data',
  },
  'cooperation-on-migration-asylum-and-border-management': {
    uk: 'Співробітництво у сфері міграції, притулку та управління кордонами',
    en: 'Cooperation on migration, asylum and border management',
  },
  'movement-persons': {
    uk: 'Рух осіб',
    en: 'Movement of persons',
  },
  'cooperation-fight-against-illicit': {
    uk: 'Співробітництво у боротьбі з незаконним обігом наркотичних засобів, прекурсорів та психотропних речовин',
    en: 'Cooperation in the fight against illicit drugs, and on precursors and psychotropic substances',
  },
  'fight-against-crime': {
    uk: 'Боротьба зі злочинністю та корупцією, правове співробітництво',
    en: 'Fight against crime and corruption, legal cooperation',
  },
  'technical-barriers-to-trade': {
    uk: 'Технічні бар’єри у торгівлі (ТБТ)',
    en: 'Technical barriers to trade (TBT)',
  },
  'sanitary-phytosanitary-measures': {
    uk: 'Санітарні та фітосанітарні заходи (СФЗ)',
    en: 'Sanitary and phytosanitary measures (SPS)',
  },
  'measures-applicable-to-main-live-animal-categories': {
    uk: 'Заходи, що застосовуються до основних категорій живих тварин',
    en: 'Measures applicable to main live animal categories',
  },
  'measures-applicable-to-products-of-animal-origin': {
    uk: 'Заходи, що застосовуються до продуктів тваринного походження',
    en: 'Measures applicable to products of animal origin',
  },
  'food-safety': {
    uk: 'Безпечність харчових продуктів',
    en: 'Food safety',
  },
  'plants-plant-products-and-other-objects': {
    uk: 'Рослини, рослинна продукція та інші об’єкти регулювання',
    en: 'Plants, plant products and other objects',
  },
  'measures-applicable-to-food-and-feed-additives': {
    uk: 'Заходи, що застосовуються до харчових та кормових добавок',
    en: 'Measures applicable to food and feed additives',
  },
  'animal-welfare-standards': {
    uk: 'Стандарти утримання та поводження з тваринами',
    en: 'Animal welfare standards',
  },
  'chemicals-composite-products-and-gmos': {
    uk: 'Хімічні речовини, змішані продукти та ГМО',
    en: 'Chemicals, composite products, and GMOs',
  },
  'customs-and-trade-facilitation': {
    uk: 'Митні питання та сприяння торгівлі',
    en: 'Customs and trade facilitation',
  },
  'establishment-trade-in-services-and-electronic-commerce': {
    uk: 'Заснування підприємницької діяльності, торгівля послугами та електронна торгівля',
    en: 'Establishment, trade in services and electronic commerce',
  },
  'postal-and-courier-services': {
    uk: 'Поштові та кур’єрські послуги',
    en: 'Postal and courier services',
  },
  'electronic-communications-and-electronic-commerce': {
    uk: 'Електронна торгівля',
    en: 'Electronic commerce',
  },
  'financial-services': {
    uk: 'Фінансові послуги',
    en: 'Financial services',
  },
  banking: {
    uk: 'Банківська сфера',
    en: 'Banking',
  },
  securities: {
    uk: 'Цінні папери',
    en: 'Securities',
  },
  insurance: {
    uk: 'Страхування',
    en: 'Insurance',
  },
  'market-infrastructure': {
    uk: 'Ринкова інфраструктура',
    en: 'Market infrastructure',
  },
  'payment-services': {
    uk: 'Платіжні послуги',
    en: 'Payment Services',
  },
  'transport-services': {
    uk: 'Транспортні послуги',
    en: 'Transport services',
  },
  'maritime-services': {
    uk: 'Морські послуги',
    en: 'Maritime services',
  },
  'public-procurement': {
    uk: 'Державні закупівлі',
    en: 'Public procurement',
  },
  'intellectual-property': {
    uk: 'Інтелектуальна власність',
    en: 'Intellectual property',
  },
  'standards-concerning-intellectual-property-rights': {
    uk: 'Стандарти, що стосуються прав інтелектуальної власності',
    en: 'Standards concerning intellectual property rights',
  },
  'protection-of-intellectual-property-rights': {
    uk: 'Захист прав інтелектуальної власності',
    en: 'Protection of intellectual property rights',
  },
  competition: {
    uk: 'Конкуренція',
    en: 'Competition',
  },
  'antitrust-and-mergers': {
    uk: 'Антиконкурентні дії та злиття',
    en: 'Antitrust and mergers',
  },
  'state-aid': {
    uk: 'Державна допомога',
    en: 'State aid',
  },
  energy: {
    uk: 'Енергетика',
    en: 'Energy',
  },
  electricity: {
    uk: 'Електроенергія',
    en: 'Electricity',
  },
  gas: {
    uk: 'Газ',
    en: 'Gas',
  },
  'energy-efficiency': {
    uk: 'Енергоефективність',
    en: 'Energy efficiency',
  },
  'renewable-energy-sources': {
    uk: 'Відновлювані джерела енергії (ВДЕ)',
    en: 'Renewable energy sources (RES)',
  },
  'energy-infrastructure': {
    uk: 'Енергетична інфраструктура',
    en: 'Energy infrastructure',
  },
  'statistics-on-natural-gas-and-electricity-prices': {
    uk: 'Статистика цін на природний газ та електроенергію',
    en: 'Statistics on natural gas and electricity prices',
  },
  'cross-sectoral-commitments': {
    uk: 'Крос-секторальні зобов’язання',
    en: 'Cross-sectoral commitments',
  },
  'energy-regulatory-authority': {
    uk: 'Енергетичний регулятор',
    en: 'Energy regulatory authority',
  },
  'rules-of-integrity-and-transparency-in-the-wholesale-electricity-and-gas-market': {
    uk: 'Правила доброчесності та прозорості на гуртовому ринку електроенергії та газу',
    en: 'Rules of integrity and transparency in the wholesale electricity and gas market',
  },
  'restructuring-of-the-coal-sector': {
    uk: 'Реструктуризація вугільного сектору',
    en: 'Restructuring of the coal sector',
  },
  'lanuclearbor-law': {
    uk: 'Ядерна енергетика',
    en: 'Nuclear',
  },
  'prospection-and-exploration-of-hydrocarbons': {
    uk: 'Розвідка і видобування вуглеводнів',
    en: 'Prospection and exploration of hydrocarbons',
  },
  'oil-and-petroleum-products': {
    uk: 'Нафта і нафтопродукти',
    en: 'Oil and petroleum products',
  },
  taxation: {
    uk: 'Оподаткування',
    en: 'Taxation',
  },
  'indirect-taxation': {
    uk: 'Непряме оподаткування: акцизні збори / податки',
    en: 'Indirect taxation: excise duties / taxes',
  },
  'indirect-taxation-value-added-tax': {
    uk: 'Непряме оподаткування: податок на додану вартість',
    en: 'Indirect taxation: value added tax',
  },
  'cooperation-further-improvement-of-economic-relations': {
    uk: 'Співробітництво з метою подальшого покращення економічних відносин, торгівлі, інвестицій та добросовісної конкуренції',
    en: 'Cooperation with a view to the further improvement of economic relations, trade, investment and fair competition',
  },
  statistics: {
    uk: 'Статистика',
    en: 'Statistics',
  },
  environment: {
    uk: 'Охорона навколишнього природного середовища',
    en: 'Environment',
  },
  'environmental-management-and-integration-of-environment-into-other-policy-areas': {
    uk: 'Управління довкіллям та інтеграція екологічної політики у інші галузеві політики',
    en: 'Environmental management and integration of environment into other policy areas',
  },
  'air-quality': {
    uk: 'Якість атмосферного повітря',
    en: 'Air quality',
  },
  'waste-and-resource-management': {
    uk: 'Управління відходами та ресурсами',
    en: 'Waste and resource management',
  },
  'water-quality-and-water-resource-management-including-marine-environment': {
    uk: 'Якість води та управління водними ресурсами, включаючи морське середовище',
    en: 'Water quality and water resource management, including marine environment',
  },
  'industrial-pollution-and-industrial-hazards': {
    uk: 'Промислове забруднення та техногенні загрози',
    en: 'Industrial pollution and industrial hazards',
  },
  'nature-protection': {
    uk: 'Охорона природи',
    en: 'Nature protection',
  },
  'climate-change-and-protection-of-the-ozone-layer': {
    uk: 'Зміна клімату та захист озонового шару',
    en: 'Climate change and protection of the ozone layer',
  },
  'genetically-modified-organisms': {
    uk: 'Генетично модифіковані організми',
    en: 'Genetically modified organisms',
  },
  transport: {
    uk: 'Транспорт',
    en: 'Transport',
  },
  'general-provisions-for-all-modes-of-transport': {
    uk: 'Загальні положення всіх видів транспорту',
    en: 'General provisions for all modes of transport',
  },
  'road-transport': {
    uk: 'Автомобільний транспорт',
    en: 'Road transport',
  },
  'rail-transport': {
    uk: 'Залізничний транспорт',
    en: 'Rail transport',
  },
  aviation: {
    uk: 'Повітряний (авіаційний) транспорт',
    en: 'Air (aviation) transport',
  },
  maritime: {
    uk: 'Морський транспорт',
    en: 'Maritime transport',
  },
  'inland-waterway': {
    uk: 'Внутрішньоводний транспорт',
    en: 'Inland waterway transport',
  },
  combined: {
    uk: 'Комбіновані перевезення',
    en: 'Combined transport',
  },
  'activity-of-companies': {
    uk: 'Діяльність компаній',
    en: 'Company law',
  },
  'establishment-and-activity-of-companies': {
    uk: 'Заснування та діяльність компаній',
    en: 'Establishment and activity of companies',
  },
  'accounting-and-auditing': {
    uk: 'Бухгалтерський облік та аудит',
    en: 'Accounting and auditing',
  },
  'corporate-governance': {
    uk: 'Корпоративне управління',
    en: 'Corporate governance',
  },
  'audio-visual-policy': {
    uk: 'Політика з питань аудіовізуальної галузі',
    en: 'Audio-visual policy',
  },
  'cross-border-broadcasting': {
    uk: 'Транскордонне мовлення',
    en: 'Cross-border broadcasting',
  },
  'audio-visual-media-services': {
    uk: 'Аудіовізуальні медіа-послуги',
    en: 'Audio-visual media services',
  },
  'agriculture-and-rural-development': {
    uk: 'Сільське господарство та розвиток сільських територій',
    en: 'Agriculture and rural development',
  },
  'quality-policy': {
    uk: 'Політика якості',
    en: 'Quality policy',
  },
  'genetically-modified-crops': {
    uk: 'Генетично модифіковані зернові',
    en: 'Genetically modified crops',
  },
  'organic-farming': {
    uk: 'Органічне фермерство',
    en: 'Organic farming',
  },
  biodiversity: {
    uk: 'Біорозмаїття',
    en: 'Biodiversity',
  },
  'marketing-standards-for-plants-seeds-of-plants-products-derived-from-plants': {
    uk: 'Стандарти торгівлі рослинами, насінням рослин, продуктами, отриманими з рослин, фруктами та овочами',
    en: 'Marketing standards for plants, seeds of plants, products derived from plants, fruits and vegetables',
  },
  'labelling-packaging-marketing-and-consumer-protection': {
    uk: 'Вимоги щодо маркування, пакування, торгівлі та захисту прав споживача щодо цукру, фруктових соків, екстрактів кави та цикорію, какао і шоколаду, фруктових джемів, желе та мармеладу, оливкової олії',
    en: 'Requirements for labelling, packaging, marketing and consumer protection concerning sugar, fruit juices, coffee and chicory extracts, cocoa and chocolate, fruit jams, jellies and marmalades, and olive oil',
  },
  'seed-production-propagating-material-of-fruit-plants': {
    uk: 'Вимоги у сфері насінництва, матеріалу для розмноження фруктових рослин, винограду та декоративних рослин, лісового репродуктивного матеріалу, насіння злакових культур',
    en: 'Requirements in the field of seed production, propagating material of fruit plants, vine and ornamental plants, forest reproductive material, and cereal seed',
  },
  'rules-of-regulation-in-the-market-of-agricultural-products': {
    uk: 'Вимоги у сфері правил регулювання на ринку сільськогосподарської продукції',
    en: 'Requirements in the field of the rules of regulation in the market of agricultural products',
  },
  'feed-in-accordance-with-eu-rules': {
    uk: 'Вимоги щодо кормів відповідно до правил ЄС',
    en: 'Requirements concerning feed in accordance with EU rules',
  },
  'marketing-standards-for-live-animal-products': {
    uk: 'Стандарти торгівлі живими тваринами та продуктами тваринництва',
    en: 'Marketing standards for live animals and animal products',
  },
  'regionalization-and-zoning-of-pest-free-areas-and-protected-areas': {
    uk: 'Регіоналізація і зонування територій, вільних від шкідників, та захищених зон',
    en: 'Regionalization / zoning, pest-free areas and protected zones',
  },
  space: {
    uk: 'Космос',
    en: 'Space',
  },
  'consumer-protection': {
    uk: 'Захист прав споживачів',
    en: 'Consumer protection',
  },
  'product-safety': {
    uk: 'Безпечність продукції',
    en: 'Product safety',
  },
  marketing: {
    uk: 'Маркетинг',
    en: 'Marketing',
  },
  'contract-law': {
    uk: 'Договірне право',
    en: 'Contract law',
  },
  'unfair-contract-terms': {
    uk: 'Недобросовісні умови контракту',
    en: 'Unfair contract terms',
  },
  'consumer-credit': {
    uk: 'Споживче кредитування',
    en: 'Consumer credit',
  },
  redress: {
    uk: 'Компенсація (рекомендаційний)',
    en: 'Redress (recommendatory)',
  },
  enforcement: {
    uk: 'Впровадження',
    en: 'Enforcement',
  },
  'consumer-protection-cooperation': {
    uk: 'Співробітництво щодо захисту прав споживачів',
    en: 'Consumer protection cooperation',
  },
  'social-policy': {
    uk: 'Соціальна політика',
    en: 'Social policy',
  },
  'labour-law': {
    uk: 'Трудове законодавство',
    en: 'Labour law',
  },
  'anti-discrimination-and-gender-equality': {
    uk: 'Антидискримінація та гендерна рівність',
    en: 'Anti-discrimination and gender equality',
  },
  'health-and-safety-at-work': {
    uk: `Здоров'я і безпека праці`,
    en: 'Health and safety at work',
  },
  'cooperation-on-social-protection-and-social-inclusion': {
    uk: 'Співпраця з питань соціального захисту та соціального включення',
    en: 'Cooperation on social protection and social inclusion',
  },
  'public-health': {
    uk: `Громадське здоров'я`,
    en: 'Public health',
  },
  'strengthening-the-health-care-system-of-ukraine-and-its-potential': {
    uk: `Зміцнення системи охорони здоров'я України та її потенціалу`,
    en: 'Strengthening the health care system of Ukraine and its potential',
  },
  'communicable-diseases': {
    uk: `Інфекційні захворювання`,
    en: 'Communicable diseases',
  },
  cancer: {
    uk: `Ракові захворювання`,
    en: 'Cancer',
  },
  tobacco: {
    uk: `Тютюн`,
    en: 'Tobacco',
  },
  alcohol: {
    uk: `Алкоголь`,
    en: 'Alcohol',
  },
  'mental-health-drug-addiction': {
    uk: `Психічне здоров'я – наркотична залежність`,
    en: 'Mental health – drug addiction',
  },
  'transplantation-of-tissues-and-cells': {
    uk: `Трансплантація тканин та клітин`,
    en: 'Transplantation of tissues and cells',
  },
  'blood-safety': {
    uk: `Безпека крові`,
    en: 'Blood safety',
  },
  'prevention-of-injury-and-promotion-of-safety-recommendatory': {
    uk: `Попередження травматизму та пропагування безпеки (рекомендаційний)`,
    en: 'Prevention of injury and promotion of safety (recommendatory)',
  },
  'education-training-and-youth': {
    uk: `Освіта, навчання та молодь`,
    en: 'Education, training and youth',
  },
  'financial-cooperation': {
    uk: `Фінансове співробітництво та положення щодо боротьби із шахрайством`,
    en: 'Financial cooperation',
  },
  'fight-against-fraud': {
    uk: `Боротьба з шахрайством`,
    en: 'Fight against fraud',
  },
  nuclear: {
    uk: `Ядерна енергетика`,
    en: 'Nuclear',
  },
  'convergence-in-the-field-of-foreign-and-security-policy': {
    uk: `Конвергенція у сфері зовнішньої та безпекової політики`,
    en: 'Convergence in foreign and security policy',
  },
  'space-security-component': {
    uk: `Космічна сфера (безпековий компонент)`,
    en: 'Space (security component)',
  },
  cybercrime: {
    uk: `Протидія кіберзагрозам`,
    en: 'Cybercrime',
  },
  telecoms: {
    uk: `Телекомунікації`,
    en: 'Telecoms',
  },
  'regulatory-agency': {
    uk: `Регулятор`,
    en: 'Regulatory agency',
  },
  'electronic-communications': {
    uk: `Телекомунікаційні послуги`,
    en: 'Electronic communications',
  },
  'trust-services': {
    uk: `Довірчі послуги`,
    en: 'Trust services',
  },
};

export const getTranslations = (field, curLang) => (translations[field] && translations[field][curLang]) || '';
