import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import localeUK from 'date-fns/locale/uk';
import localeEN from 'date-fns/locale/en-US';
import {MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import {getTranslations} from "../../helpers/translations";

const DatePicker = ({selected, name, onChange, label, views, format, curLang, disabled}) => {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={curLang === 'uk' ? localeUK : localeEN}>
      <KeyboardDatePicker
        disableToolbar
        views={views}
        inputVariant="outlined"
        format={format}
        margin="normal"
        name={name}
        label={label}
        value={selected}
        disabled={disabled}
        onChange={(date => onChange(date))}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        okLabel={getTranslations('ok', curLang)}
        cancelLabel={getTranslations('cancel', curLang)}
        invalidDateMessage={getTranslations('invalid_date', curLang)}
        minDateMessage={getTranslations('minDateMessage', curLang)}
        maxDateMessage={getTranslations('maxDateMessage', curLang)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;