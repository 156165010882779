import React, {useEffect, useState} from 'react';
import {getCommitmentDetailRequest} from "../../actions/commitments";
import {useDispatch, useSelector} from "react-redux";
import {getTranslations} from "../../helpers/translations";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import PropTypes from "prop-types";
import './Commitment.scss'
import TaskModal from "../../components/modals/TaskModal/TaskModal";
import Tasks from "./Tasks/Tasks";
import MyButton from "../../components/buttons/Button/Button";
import Execution from "../../components/Execution/Execution";
import CommitmentModal from "../../components/modals/CommitmentModal/CommitmentModal";
import LegacyActModal from "../../components/modals/LegacyActModal/LegacyActModal";
import ActionButton from "../../components/buttons/ActionButton/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from '@material-ui/core/MenuItem';
import DropDown from '../../components/DropDown/DropDown';
import Collapse from "@material-ui/core/Collapse";

const Commitment = (props) => {

  const dispatch = useDispatch();
  const curLang = useSelector(state => state.commitments.curLang);
  const path = props.location.pathname.split('/').slice(2);
  const commitment = useSelector(state => state.commitments.commitment);
  const tasks = useSelector(state => state.commitments.tasks);
  const loading = useSelector(state => state.commitments.loading);
  const admin = useSelector(state => state.auth.admin);
  const currentTask = useSelector(state => state.commitments.currentTask);
  const locale = curLang === 'uk' ? 'uk-UA' : 'en-US';
  const euActs = commitment.eu_acts && commitment.eu_acts.map((i) => 
    <a href={i.link} target='_blank' key={i.name_ua} rel='noopener noreferrer'><MenuItem>{curLang === 'uk' ? i.name_ua : i.name_en}</MenuItem></a>
  );

  useEffect(() => {
    dispatch(getCommitmentDetailRequest(path.join('/')));
  }, []); // eslint-disable-line

  const [isCommitmentModalOpen, setIsCommitmentModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isActModalOpen, setIsActModalOpen] = useState(false);
  const [showAllAssignees, setShowAllAssignees] = useState(false);

  return (
    <>
      <Breadcrumbs path={path} curLang={curLang} linkToCommitments commitment={commitment}/>

      {loading
        ? <div className='loader'/>
        : <>
          {Object.keys(commitment).length
            ? <>
              <div className="commitment">
                <div className="commitment__top-info">
                  <div className="commitment__left">
                    <p className='text'>{getTranslations('commitment_title', curLang)} №{commitment.number}</p>
                    <h2 className="heading">{curLang === 'uk' ? commitment.name_ua : commitment.name_en}</h2>
                    {admin && (
                      <div className='commitment__edit'>
                        <ActionButton edit doAction={() => setIsCommitmentModalOpen(true)}
                        />
                      </div>
                    )}
                    <div className="commitment__author">
                      <div className='text'>
                        {commitment.assignees.length === 1
                          ? <>
                              <b>{getTranslations('assignee', curLang)}:</b>
                              <Tooltip title={commitment.assignees[0]
                                ? curLang === 'uk' ? commitment.assignees[0].name_ua : commitment.assignees[0].name_en
                                : ''} arrow>
                                <span>
                                  {commitment.assignees[0] && (
                                    curLang === 'uk' ? commitment.assignees[0].name_ua : commitment.assignees[0].name_en
                                  )}
                                </span>
                              </Tooltip>
                            </>
                          : <>
                              <b>{getTranslations('assignees', curLang)}:</b>
                              <div className="commitment__assignees-list">
                                <ul>
                                  {commitment.assignees.slice(0, 4).map((item, index) =>
                                    <li key={item._id}>
                                      <Tooltip title={curLang === 'uk' ? item.name_ua : item.name_en} arrow>
                                        <span>{curLang === 'uk' ? item.name_ua : item.name_en}</span>
                                      </Tooltip>
                                    </li>
                                  )}
                                </ul>
                                {commitment.assignees.length > 4 && (
                                  <>
                                    <ul>
                                      <Collapse in={showAllAssignees} timeout="auto" unmountOnExit>
                                        {commitment.assignees.slice(4).map((item, index) =>
                                          <li key={item._id}>
                                            <Tooltip title={curLang === 'uk' ? item.name_ua : item.name_en} arrow>
                                              <span>{curLang === 'uk' ? item.name_ua : item.name_en}</span>
                                            </Tooltip>
                                          </li>
                                        )}
                                      </Collapse>
                                    </ul>
                                    <div onClick={() => setShowAllAssignees(!showAllAssignees)} className='arrow-wrap'>
                                      <div className={`arrow ${showAllAssignees ? 'arrow-open' : ''}`}/>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                        }

                      </div>
                      {commitment.deadline && (
                        <div className='text'>
                          <b>{getTranslations('deadline', curLang)}:</b>
                          {new Date(commitment.deadline + '').toLocaleDateString(locale, {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                          })}
                        </div>
                      )}
                      {
                        commitment.eu_acts && commitment.eu_acts.length ?
                          <DropDown title={getTranslations('eu_acts', curLang)} listItems={euActs} />
                          :
                          null
                      }
                    </div>
                  </div>
                  <div className="commitment__right">
                    {typeof commitment.execution !== 'undefined' && (
                      <Execution curLang={curLang} percentage={commitment.execution}/>
                    )}
                  </div>
                </div>
                <p className='text'>{curLang === 'uk' ? commitment.description_ua : commitment.description_en}</p>
              </div>
              <div className="heading-wrap">
                <div className="w-50 w-100-mob d-flex space-between">
                  <h3>{getTranslations('tasks_title', curLang)}</h3>
                  {admin && (
                    <MyButton
                      type='button'
                      withAddIcon
                      titleSlug='add_task'
                      color='primary'
                      doAction={() => setIsTaskModalOpen(true)}
                    />
                  )}
                </div>
                {admin && currentTask && !!Object.keys(currentTask).length && (
                  <MyButton
                    type='button'
                    withAddIcon
                    titleSlug='add_act'
                    color='primary'
                    doAction={() => setIsActModalOpen(true)}
                  />
                )}
              </div>
              <Tasks
                tasks={tasks}
                curLang={curLang}
                path={path}
                currentTask={currentTask}
                admin={admin}
              />
              </>
            : <p className='commitment__empty'>{getTranslations('empty_commitment', curLang)}</p>
          }
        </>
      }


      <CommitmentModal
        titleSlug='edit_commitment'
        curLang={curLang}
        open={isCommitmentModalOpen}
        setIsModalOpen={() => setIsCommitmentModalOpen(false)}
        path={path}
        commitment={commitment}
      />

      <TaskModal
        titleSlug='new_task'
        open={isTaskModalOpen}
        setIsModalOpen={() => setIsTaskModalOpen(false)}
        path={path}
        curLang={curLang}
      />

      <LegacyActModal
        titleSlug='new_act'
        open={isActModalOpen}
        setIsModalOpen={() => setIsActModalOpen(false)}
        path={path}
        curLang={curLang}
        currentTask={currentTask}
      />
    </>
  )
};

Commitment.propTypes = {
  commitment: PropTypes.object,
  currentTask: PropTypes.object,
  path: PropTypes.array,
  tasks: PropTypes.array,
  curLang: PropTypes.string,
  loading: PropTypes.bool,
  admin: PropTypes.object
};

export default Commitment;